import { css, useTheme } from '@emotion/react'
import { Link, Modal } from '@fluentui/react'
import { difference, intersection, xor } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { buttonStyles } from '../../components/shared/Buttons'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import {
  useRdot360SelectedAccountsApiContext,
  useRdot360AccountContext
} from '../../store/rdot360Context'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import {
  AccountSelector,
  accountSelectorColumnNames,
  useAccountSelectorTableStore
} from '../AccountSelector'
import { CustomGroupSelector } from '../AccountSelector/CustomGroupSelector'
import { Icon } from '../Icons/Icon'
import { ManageAccountNicknames } from '../ManageAccountNicknames/ManageAccountNicknames'
import { useManageAccountNicknamesStore } from '../ManageAccountNicknames/store'
import { CustomAccountGroupModal } from './CustomAccountGroupModal'
import { EditCagModal } from './EditCagModal'
import { SidePanelFilter } from './SidePanelFilter'
import { useSidePanelStore } from './store'

export const getClasses = () => ({
  container: css({
    backgroundColor: 'white',
    height: '100%'
  }),
  wrapper: css({
    margin: '0 9px'
  })
})

export const SidePanel = memo(() => {
  const {
    setSelectedAccountIds,
    selectedAccountIds: contextSelectedIds,
    accounts,
    includeClosedAccounts,
    setIncludeClosedAccounts
  } = useRdot360AccountContext()
  const {
    isOpen,
    setIsOpen,
    showManageAccountsModal,
    setShowManageAccountsModal
  } = useSidePanelStore()
  const { searchText, setSearchText } = useAccountSelectorTableStore()
  const {
    selectedIds,
    setSelectedIds,
    filteredAccounts,
    grouping: [firstGroup]
  } = useAccountSelectorTableStore()
  const { isLoading: isContextLoading } = useRdot360SelectedAccountsApiContext()
  useEffect(() => {
    setSelectedIds(contextSelectedIds)
  }, [contextSelectedIds, setSelectedIds])
  const { filteredAccountNumbers } = useRdot360CagsContext(true)

  const applicableSelectedIds = useMemo(
    () =>
      firstGroup === 'cag'
        ? intersection(
            intersection(filteredAccountNumbers, selectedIds),
            filteredAccounts?.map((x) => x.id) || []
          ).filter(isNotNullOrUndefined) || []
        : intersection(
            selectedIds,
            filteredAccounts?.map((x) => x.id) || []
          ).filter(isNotNullOrUndefined),
    [filteredAccounts, filteredAccountNumbers, firstGroup, selectedIds]
  )

  const handleFilterApplyClick = useCallback(() => {
    setSelectedAccountIds(applicableSelectedIds)
  }, [applicableSelectedIds, setSelectedAccountIds])

  const [isCreateCAGHidden, setIsCreateCAGHidden] = useState(true)
  const [isEditCAGHidden, setIsEditCAGHidden] = useState(true)
  const hideCreateCAG = useCallback(() => setIsCreateCAGHidden(true), [])
  const hideEditCAG = useCallback(() => setIsEditCAGHidden(true), [])

  const handleCreateCustom = useCallback(() => {
    setIsCreateCAGHidden(false)
  }, [])
  const handleEditCag = useCallback(() => {
    setIsEditCAGHidden(false)
  }, [])

  const handleCancelClick = useCallback(() => {
    setSelectedIds(contextSelectedIds)
  }, [contextSelectedIds, setSelectedIds])

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  const toggleIncludeClosedAccounts = useCallback(() => {
    setIncludeClosedAccounts(!includeClosedAccounts)
  }, [includeClosedAccounts, setIncludeClosedAccounts])

  const selectionHasChanged = useMemo(
    () => xor(contextSelectedIds, applicableSelectedIds).length > 0,
    [applicableSelectedIds, contextSelectedIds]
  )

  const isAllSelected = useMemo(
    () =>
      difference(
        accounts?.map((x) => x.id),
        applicableSelectedIds
      ).length === 0,
    [accounts, applicableSelectedIds]
  )

  const handleSelectAll = () => {
    isAllSelected
      ? setSelectedIds([])
      : setSelectedIds(
          accounts
            ?.map((x) => x.id || x.CustodyAccount)
            .filter(isNotNullOrEmpty)
        )
  }

  const handleSettingsClick = useCallback(
    () => setShowManageAccountsModal(true),
    [setShowManageAccountsModal]
  )
  const { reset } = useManageAccountNicknamesStore()

  const onDismissModal = useCallback(() => {
    setShowManageAccountsModal(false)
    reset()
  }, [setShowManageAccountsModal, reset])

  const theme = useTheme()
  const classes = useMemo(() => getClasses(), [])
  const scrollContainer = useRef<HTMLDivElement>(null)

  return (
    <>
      <div css={classes.container}>
        <div
          ref={scrollContainer}
          css={[
            advisoryModuleStyles.fancyScroll,
            { height: '100%', width: isOpen ? 346 : 40 }
          ]}
        >
          <aside>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                css={{
                  height: '18px',
                  width: '18px',
                  margin: '8px',
                  alignSelf: 'flex-end'
                }}
              >
                <Icon
                  type={isOpen ? 'CollapseAccount' : 'ExpandAccount'}
                  height={18}
                  width={18}
                  onClick={toggleIsOpen}
                  color={
                    isOpen
                      ? theme.colors.primaryDarkBlue
                      : theme.colors.primaryWhite
                  }
                  fillColor={isOpen ? undefined : theme.colors.primaryDarkBlue}
                />
              </div>
              {!isOpen ? (
                <div
                  css={{
                    transform: 'rotate(180deg)',
                    textOrientation: 'mixed',
                    writingMode: 'vertical-lr',
                    marginRight: '10px',
                    whiteSpace: 'nowrap',
                    textDecoration: 'underline',
                    color: '#1D679D'
                  }}
                >
                  {`${contextSelectedIds?.length || 0}/${
                    accounts?.length || 0
                  } Accounts Selected`}
                </div>
              ) : (
                ''
              )}
            </div>
            {isOpen && (
              <div
                css={[classes.wrapper, { display: isOpen ? 'block' : 'none' }]}
              >
                <SidePanelFilter />

                <>
                  <div
                    css={{
                      marginLeft: '0.5rem',
                      padding: '1rem 0rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        style={{ cursor: 'pointer', margin: 0 }}
                        checked={includeClosedAccounts}
                        onChange={toggleIncludeClosedAccounts}
                      />
                      <label
                        css={{
                          marginLeft: '5px',
                          flex: '0.55',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Include Closed Accounts
                      </label>
                    </div>
                    <div>
                      <div css={{ marginRight: 8 }}>
                        <button
                          disabled={isContextLoading || !accounts?.length}
                          css={{
                            border: 'none',
                            backgroundColor: 'transparent'
                          }}
                        >
                          <Icon
                            type="Settings"
                            width={16}
                            height={16}
                            onClick={handleSettingsClick}
                          />
                        </button>
                      </div>
                      {showManageAccountsModal && (
                        <Modal
                          isOpen={showManageAccountsModal}
                          onDismiss={onDismissModal}
                          styles={{ main: { display: 'flex' } }}
                          isBlocking={true}
                        >
                          <ManageAccountNicknames
                            onDismissModal={onDismissModal}
                          />
                        </Modal>
                      )}
                    </div>
                  </div>
                  <div css={{ marginBottom: '5px' }}>
                    <Searchbox
                      searchText={searchText}
                      onChange={setSearchText}
                      width="100%"
                    />
                  </div>
                  <div css={{ marginBottom: '5px' }}>Select Account(s)</div>
                  <div
                    css={{
                      marginLeft: '7px',
                      float: 'left'
                    }}
                  >
                    {searchText.length !== 0 &&
                    filteredAccounts?.length === 0 ? (
                      ''
                    ) : (
                      <Link onClick={handleSelectAll}>
                        {isAllSelected ? 'Deselect All' : 'Select All'}
                      </Link>
                    )}
                  </div>
                  {firstGroup ===
                  accountSelectorColumnNames.customAccountGroup ? (
                    <CustomGroupSelector />
                  ) : (
                    <AccountSelector scrollContainer={scrollContainer} />
                  )}
                </>

                <div
                  css={{
                    position: 'sticky',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 10,
                    bottom: 0,
                    paddingTop: 12,
                    paddingBottom: 5
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gridColumnGap: 10
                    }}
                  >
                    <button
                      css={[buttonStyles.secondary, { flexGrow: 1 }]}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                    <button
                      css={[buttonStyles.primary, { flexGrow: 2 }]}
                      onClick={handleFilterApplyClick}
                      disabled={
                        isContextLoading ||
                        !selectionHasChanged ||
                        !applicableSelectedIds.length
                      }
                    >
                      {isContextLoading ? <LoadingComponent /> : 'Apply'}
                    </button>
                  </div>
                  <div css={{ display: 'flex', columnGap: 10 }}>
                    <button
                      css={[buttonStyles.secondary, { flexGrow: 1 }]}
                      onClick={handleCreateCustom}
                      disabled={applicableSelectedIds?.length < 1}
                    >
                      Save as Custom Group
                    </button>
                    {firstGroup === 'cag' && (
                      <button
                        css={[buttonStyles.secondary, { flexGrow: 1 }]}
                        onClick={handleEditCag}
                        disabled={applicableSelectedIds?.length < 1}
                      >
                        Edit Existing Group
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
      {!isCreateCAGHidden && (
        <CustomAccountGroupModal
          hidden={isCreateCAGHidden}
          hide={hideCreateCAG}
          selectedAccounts={applicableSelectedIds}
        />
      )}
      {!isEditCAGHidden && (
        <EditCagModal
          hidden={isEditCAGHidden}
          hide={hideEditCAG}
          selectedAccounts={applicableSelectedIds}
        />
      )}
    </>
  )
})
