import { useTheme } from '@emotion/react'
import { Dialog, Link } from '@fluentui/react'
import { difference, union } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { useCallback, useMemo, useState } from 'react'
import { SimpleOverlay } from 'shared/components/SimpleOverlay'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  Dropdown,
  IndeterminateProgressIndicator
} from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { useUpdateCagAccountsMutation } from '../../store/dynamics'
import {
  useRdot360AccountContext,
  useRdot360Context
} from '../../store/rdot360Context'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import { Icon } from '../Icons/Icon'
import { CreateEditAccountRow } from './CreateEditAccountRow'

export const EditCagModal: React.FC<{
  hidden?: boolean
  selectedAccounts?: string[]
  hide: () => void
}> = ({ hidden, hide, selectedAccounts }) => {
  const { cags } = useRdot360CagsContext()
  const dropdownOptions = useMemo(
    () =>
      cags
        ?.map((x) => ({
          key: x.rcm_customaccountgroupid || '',
          text: x.rcm_name || ''
        }))
        ?.filter(isNotNullOrUndefined),
    [cags]
  )
  const [selectedId, setSelectedId] = useState<string>()
  const selectedCag = useMemo(
    () =>
      cags
        ? cags?.find((x) => x?.rcm_customaccountgroupid === selectedId)
        : undefined,
    [cags, selectedId]
  )
  const { selectedHouseholdId } = useRdot360Context()
  const onDismiss = useCallback(() => {
    hide()
  }, [hide])
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const selectedAccountsWithCagAccounts = useMemo(
    () =>
      union(
        selectedAccounts,
        selectedCag?.rcm_cag_rcm_financialaccount?.map(
          (x) => x.rcm_accountnumber
        )
      )?.filter(isNotNullOrUndefined),
    [selectedAccounts, selectedCag?.rcm_cag_rcm_financialaccount]
  )
  const accounts = useMemo(
    () =>
      selectedAccountsWithCagAccounts
        ?.map((x) => accountLookupByAccountIdOrKey[x])
        ?.filter(isNotNullOrUndefined),
    [accountLookupByAccountIdOrKey, selectedAccountsWithCagAccounts]
  )
  const [uiSelected, setUiSelected] = useState(selectedAccounts)
  const onCheckboxClicked = useCallback(
    (accountNumber?: string) => {
      if (!accountNumber) {
        return
      }
      if (uiSelected?.includes(accountNumber)) {
        setUiSelected(uiSelected?.filter((x) => x !== accountNumber))
      } else {
        setUiSelected([...(uiSelected || []), accountNumber])
      }
    },
    [uiSelected]
  )
  const [editGroup, editResult] = useUpdateCagAccountsMutation()
  const onApply = useCallback(async () => {
    const accountIds = uiSelected
      ?.map((x) => accountLookupByAccountIdOrKey?.[x]?.accountId)
      .filter(isNotNullOrUndefined)
    const idsInCag = selectedCag?.rcm_cag_rcm_financialaccount?.map(
      (x) => x?.rcm_cdmaccountid || ''
    )
    const idsToAdd = difference(accountIds, idsInCag || [])
    const idsToRemove = difference(idsInCag, accountIds || [])
    if (
      !accountIds ||
      !selectedHouseholdId ||
      !selectedCag?.rcm_customaccountgroupid
    ) {
      return
    }
    const result = await editGroup({
      id: selectedCag?.rcm_customaccountgroupid,
      accountsToAdd: idsToAdd,
      accountsToRemove: idsToRemove
    }).unwrap()
    if (!result?.error) {
      hide()
    }
  }, [
    accountLookupByAccountIdOrKey,
    editGroup,
    hide,
    selectedCag?.rcm_cag_rcm_financialaccount,
    selectedCag?.rcm_customaccountgroupid,
    selectedHouseholdId,
    uiSelected
  ])
  const { error, isLoading } = editResult
  const isAllSelected = useMemo(
    () => uiSelected?.length === selectedAccountsWithCagAccounts?.length,
    [selectedAccountsWithCagAccounts?.length, uiSelected?.length]
  )
  const handleSelectAll = useCallback(() => {
    if (isAllSelected) {
      setUiSelected([])
    } else {
      setUiSelected(selectedAccountsWithCagAccounts)
    }
  }, [isAllSelected, selectedAccountsWithCagAccounts])
  const theme = useTheme()

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      minWidth={'450px'}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        styles: {
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            height: 0,
            padding: 0,
            zIndex: 1
          },
          content: {
            maxHeight: '750px',
            minHeight: '350px',
            backgroundColor: '#F5F5F5'
          },
          inner: { padding: 0 }
        }
      }}
    >
      {isLoading && <SimpleOverlay />}
      <div
        css={(theme) => ({
          fontSize: theme.size.lg1,
          fontWeight: theme.fontWeights.demi,
          color: theme.colors.primaryDarkBlue,
          height: '60px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 10px'
        })}
      >
        Edit Custom Account Group
      </div>
      {isLoading && <IndeterminateProgressIndicator />}
      <div css={{ padding: '0 24px 24px' }}>
        <>
          <div css={{ padding: '12px 0' }}>
            <label css={inputClasses.label}>Select Group to Edit</label>
            <Dropdown
              options={dropdownOptions || []}
              selectedKey={selectedId}
              onChange={(ev, option) => setSelectedId(option?.key as string)}
            />
          </div>
          <div css={{ padding: '12px 0' }}>
            <div css={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
              <label css={inputClasses.label}>
                Accounts Selected ({uiSelected?.length} /{' '}
                {selectedAccountsWithCagAccounts.length})
              </label>
              <Icon
                type="Info"
                width={12}
                height={12}
                color={theme.colors.tertiaryBlue1}
                title="Click Save to update this group per your account selections below."
              />
            </div>
            <Link onClick={handleSelectAll} css={{ paddingBottom: '5px' }}>
              {isAllSelected ? 'Deselect All' : 'Select All'}
            </Link>
            <div
              css={(theme) => [
                {
                  backgroundColor: theme.colors.primaryWhite,
                  padding: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 5,
                  maxHeight: '400px',
                  minHeight: '200px'
                },
                advisoryModuleStyles.fancyScroll
              ]}
            >
              {accounts?.map((account, i) => (
                <CreateEditAccountRow
                  key={i}
                  account={account}
                  isSelected={
                    account?.id ? uiSelected?.includes(account?.id) : false
                  }
                  onChange={onCheckboxClicked}
                />
              ))}
            </div>
          </div>
          <SnackBar
            type="Info"
            message="Note: Adding Non-NFS accounts to CAGs is not currently available. We are working on addressing this issue."
          />
          {error ? (
            <SnackBar
              type="Failure"
              message={(error as Error)?.message || 'An unknown error occurred'}
            />
          ) : null}
          <div
            css={{
              padding: '20px 0 20px',
              display: 'flex',
              justifyContent: 'end',
              gridColumnGap: 5
            }}
          >
            <button
              onClick={onDismiss}
              css={[buttonStyles.secondary, { width: '100px' }]}
            >
              Cancel
            </button>
            <button
              onClick={onApply}
              css={[
                buttonStyles.primary,
                {
                  width: '100px'
                }
              ]}
              disabled={(uiSelected?.length || 0) < 1 || !selectedCag}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </Dialog>
  )
}
