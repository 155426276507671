import { Stack, Text } from '@fluentui/react'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { IHousehold } from '../../../api/household.types'
import { parseDateISOStringInLocalTimezone } from '../../../shared'
import { HouseholdNameCell } from '../../Households/HouseholdNameCell'
import { AusCell } from '../core/components/AusCell'
import { DateCell } from '../core/components/DateCell'
import { IListsCellComponent } from '../core/components/ListsDataTable'
import { TextCell } from '../core/components/TextCell'
import { USDCell } from '../core/components/USDCell'
import { AssetAllocationBarContainer } from '../shared/AssetAllocationBarContainer'
import { ProductAllocationBarContainer } from '../shared/ProductAllocationBarContainer'
import { HouseholdColumnIds } from './ColumnDefinitions'

export const createHouseholdCell = (cellComponents: {
  [key: string]: React.FC<{ household: IHousehold }>
}) => {
  const HouseholdCellComponent: IListsCellComponent<IHousehold> = ({
    columnDefinition,
    item
  }) => {
    const Cell = cellComponents[columnDefinition.id] || null
    return Cell && <Cell household={item} />
  }
  return HouseholdCellComponent
}

export const HouseholdCellComponents: {
  [key: string]: React.FC<{ household: IHousehold }>
} = {
  [HouseholdColumnIds.team]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisorTeam }, i) => (
        <TextCell key={i}>{ClientAdvisorTeam || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.clientAdvisor]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisor }, i) => (
        <TextCell key={i}>{ClientAdvisor || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.clientAdvisorId]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ ClientAdvisorID }, i) => (
        <TextCell key={i}>{ClientAdvisorID || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.Office]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ HubName }, i) => (
        <TextCell key={i}>{HubName || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.Division]: ({ household }) => (
    <Stack>
      {household?.Advisors?.map(({ Division }, i) => (
        <TextCell key={i}>{Division || ''}</TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.aum]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumManaged} fractionDigits={0} />
  ),
  [HouseholdColumnIds.aus]: ({ household }) => (
    <AusCell KPI={household.householdKPI} />
  ),
  [HouseholdColumnIds.brokerageAssets]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumBrokerage} fractionDigits={0} />
  ),
  [HouseholdColumnIds.purchasingPower]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.cashAvlToTrade}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.loansOutstanding]: ({ household }) => (
    <USDCell
      value={household?.householdKPI?.LoanOutstanding}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.netNewMoney]: ({ household }) => (
    <USDCell value={household?.householdKPI?.NetNewMoney} fractionDigits={0} />
  ),
  [HouseholdColumnIds.netNewAssets]: ({ household }) => (
    <USDCell value={household?.householdKPI?.TOA} fractionDigits={0} />
  ),
  [HouseholdColumnIds.annuity]: ({ household }) => (
    <USDCell value={household?.householdKPI?.annuity} fractionDigits={0} />
  ),
  [HouseholdColumnIds.lastUpdated]: ({ household }) => (
    <DateCell value={household?.LastUpdatedAt} />
  ),
  [HouseholdColumnIds.lastModified]: ({ household }) => (
    <DateCell value={household?.lastUpdatedDate} />
  ),
  [HouseholdColumnIds.created]: ({ household }) => (
    <DateCell value={household?.createdDate} />
  ),
  [HouseholdColumnIds.productAllocation]: ({ household }) => (
    <ProductAllocationBarContainer
      productAllocations={household?.productAllocation || []}
    />
  ),
  [HouseholdColumnIds.assetAllocation]: ({ household }) => (
    <AssetAllocationBarContainer
      assetAllocations={household?.assetAllocationLvl1 || []}
    />
  ),
  [HouseholdColumnIds.householdId]: ({ household }) => (
    <TextCell>{household?.householdId}</TextCell>
  ),
  [HouseholdColumnIds.householdName]: ({ household }) => (
    <HouseholdNameCell household={household} />
  ),
  [HouseholdColumnIds.householdTags]: ({ household }) => (
    <Stack style={{ minWidth: 0 }}>
      {household.HouseholdTags?.map((t) => (
        <TextCell key={t.Id} title={t.Name}>
          {t.Name}
        </TextCell>
      ))}
    </Stack>
  ),
  [HouseholdColumnIds.revenueMTD]: ({ household }) => (
    <USDCell value={household?.revenue?.MTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueQTD]: ({ household }) => (
    <USDCell value={household?.revenue?.QTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueYTD]: ({ household }) => (
    <USDCell value={household?.revenue?.YTDcompRevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.revenueT12]: ({ household }) => (
    <USDCell value={household?.revenue?.ttmrevenue} fractionDigits={0} />
  ),
  [HouseholdColumnIds.MonthlyAvgSweepBal]: ({ household }) => (
    <USDCell value={household.MonthlyAvgSweepBal} fractionDigits={0} />
  ),
  [HouseholdColumnIds.AdvisedOnly]: ({ household }) => (
    <USDCell value={household.householdKPI?.AdvisedOnly} fractionDigits={0} />
  ),
  [HouseholdColumnIds.LoanOutstandingOutsideNfs]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.LoanOutstandingOutsideNfs}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.LoanOutstandingNfs]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.LoanOutstandingNfs}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.AdminReporting]: ({ household }) => (
    <USDCell
      value={household.householdKPI?.AdminReportingAssets}
      fractionDigits={0}
    />
  ),
  [HouseholdColumnIds.activeInd]: ({ household }) => (
    <TextCell>{household.ActiveFlag}</TextCell>
  ),
  [HouseholdColumnIds.employeeInd]: ({ household }) => (
    <TextCell>{household.employeeCode}</TextCell>
  ),
  [HouseholdColumnIds.feeEligibleInd]: ({ household }) => (
    <TextCell>{household.householdFeeIndicator}</TextCell>
  ),
  [HouseholdColumnIds.feeChargeType]: ({ household }) => (
    <TextCell>{household.chargeType}</TextCell>
  ),
  [HouseholdColumnIds.nextBillingCycleChargeDate]: ({ household }) => {
    const date = household.nextBillingCycleChargeDate
      ? parseDateISOStringInLocalTimezone(household.nextBillingCycleChargeDate)
      : undefined

    return date ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={date}
        />
      </Text>
    ) : (
      <Text>--</Text>
    )
  },
  [HouseholdColumnIds.nextBillingCycleMeasureDate]: ({ household }) => {
    const date = household.nextBillingCycleMeasureDate
      ? parseDateISOStringInLocalTimezone(household.nextBillingCycleMeasureDate)
      : undefined

    return date ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={date}
        />
      </Text>
    ) : (
      <Text>--</Text>
    )
  },
  [HouseholdColumnIds.billingAUS]: ({ household }) => (
    <USDCell value={household?.householdKPI?.AumTotal_NFS} fractionDigits={0} />
  ),
  [HouseholdColumnIds.RockConnectAccBalance]: ({ household }) => (
    <USDCell value={household?.RockConnectAccBalance} fractionDigits={0} />
  )
}

export const HouseholdCell = createHouseholdCell(HouseholdCellComponents)
