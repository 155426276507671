import { getRandomColor } from 'api/random'
import { fromPairs } from 'lodash'
import assetTaxonomy from '../../../../../metadata/asset-taxonomy.json'

const getAssetTaxonomyByLevel = (assetLevel: string | number) =>
  fromPairs(
    assetTaxonomy
      .filter(({ level }) => level === assetLevel)
      .map(({ description, color }) => [description, color])
  )

const assetClassL1ColorLookup = getAssetTaxonomyByLevel(1)
const assetClassL2ColorLookup = getAssetTaxonomyByLevel(2)
const assetClassL3ColorLookup = getAssetTaxonomyByLevel(3)
const assetClassL4ColorLookup = getAssetTaxonomyByLevel(4)
const productTypeColorLookup = getAssetTaxonomyByLevel('NFS')
const sectorClassColorLookup = getAssetTaxonomyByLevel('Sector')

const taxonomyTypeLookup: Record<string, Record<string, string>> = {
  '1': assetClassL1ColorLookup,
  L1: assetClassL1ColorLookup,
  L2: assetClassL2ColorLookup,
  L3: assetClassL3ColorLookup,
  L4: assetClassL4ColorLookup,
  NFS: productTypeColorLookup,
  P: productTypeColorLookup,
  S: sectorClassColorLookup
}

export const getTaxonomyColorLookupByType = (type: string | number) =>
  taxonomyTypeLookup[type?.toString() || '']

export const getTaxonomyColor = (type: string | number, name: string) =>
  getTaxonomyColorLookupByType(type)?.[name] ?? getRandomColor()
