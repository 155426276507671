import { Icon, Stack, Text } from '@fluentui/react'
import React, { memo } from 'react'
import { useClasses } from 'shared/hooks/useClasses'
import { useUserSettings } from 'shared/hooks/useUserSettings'
import { IClient } from '../../../api/client.types'
import { USD } from '../../../shared/components/Formatting'
import { getThemedClasses } from '../shared/styles'
import { ConnectedMaskedHighlight, Highlight } from './Highlight'
export interface IClientSearchResultsProps {
  className?: string
  clients: IClient[]
  onClientClick: (client: IClient) => void
  query?: string
}

export const ClientSearchResults: React.FC<IClientSearchResultsProps> = memo(
  ({ className, clients, onClientClick, query }) => {
    return (
      <table className={className}>
        <thead>
          <tr>
            <td />
            <td style={{ textAlign: 'right' }}>
              <Text variant="small">
                <b>REP</b>
              </Text>
            </td>
            <td>
              <Text variant="small">
                <b>AUS</b>
              </Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => {
            const {
              id,
              Account,
              LegalEntityName,
              srcClientNumber,
              ClientAdvisor,
              ClientAdvisorTeam,
              ClientAdvisorID,
              ClientKPI
            } = client
            const onClick = () => onClientClick(client)
            return (
              <tr key={id} onClick={onClick}>
                <td>
                  <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                    <Stack.Item verticalFill={true} align="center">
                      <Icon iconName="Contact" />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Text
                        nowrap={true}
                        block={true}
                        variant="medium"
                        title={LegalEntityName}
                      >
                        <Highlight
                          text={LegalEntityName || 'No Name'}
                          highlight={query}
                        />
                      </Text>
                      {srcClientNumber && (
                        <Text variant="small">
                          <Highlight text={srcClientNumber} highlight={query} />
                        </Text>
                      )}
                      <Text nowrap={true} block={true} variant="small">
                        {Account?.length || 0} Account(s)
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                  >
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Text nowrap={true} block={true} variant="medium">
                        {ClientAdvisor}
                      </Text>

                      <Text nowrap={true} block={true} variant="small">
                        {ClientAdvisorTeam}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text nowrap={true} block={true} variant="small">
                        {ClientAdvisorID}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td>
                  <Text nowrap={true} block={true} variant="small">
                    <USD value={ClientKPI?.AumTotal || 0} />
                  </Text>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)

export const ClientSearchResultsV2: React.FC<IClientSearchResultsProps> = memo(
  ({ className, clients, onClientClick, query }) => {
    const classes = useClasses(getThemedClasses)
    const { enablePreviewFeatures } = useUserSettings()
    return (
      <div
        className={className}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {clients.map((client) => {
          const {
            id,
            RockConnectAccBalance,
            legalEntityType,
            LegalEntityName,
            ClientAdvisorID,
            ClientAdvisor,
            ClientKPI,
            srcClientNumber
          } = client
          const onClick = () => onClientClick(client)
          return (
            <div key={id} className={classes.rowItem} onClick={onClick}>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <Icon iconName="Contact" />
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Text
                    nowrap={true}
                    block={true}
                    variant="medium"
                    title={LegalEntityName}
                  >
                    <ConnectedMaskedHighlight
                      text={LegalEntityName || 'No Name'}
                      highlight={query}
                    />
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text
                      styles={{
                        root: { fontSize: '11px', fontWeight: '300' }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '3px'
                        }}
                      >
                        <div title={ClientAdvisor}>{ClientAdvisorID || ''}</div>
                        {srcClientNumber && (
                          <>
                            |
                            <div title="Client Number">
                              <Highlight
                                text={srcClientNumber}
                                highlight={query}
                              />
                            </div>
                          </>
                        )}
                        <>|</>
                        <div title="Legal Entity Type">{legalEntityType}</div>
                        <>|</>
                        <div title="Account Balances">
                          <USD
                            value={
                              (enablePreviewFeatures &&
                                RockConnectAccBalance) ||
                              ClientKPI?.AumTotal ||
                              0
                            }
                            fractionDigits={0}
                          />
                        </div>
                      </div>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
)
