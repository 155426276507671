import { Text } from '@fluentui/react'
import React from 'react'
import { FormattedNumber } from 'react-intl'

export const NumberCell: React.FC<{
  value?: number
  fractionDigits?: number
}> = ({ value, fractionDigits }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2
  return value != null ? (
    <Text styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}>
      <FormattedNumber
        value={value}
        maximumFractionDigits={decimals}
        minimumFractionDigits={decimals}
      />
    </Text>
  ) : (
    <Text>--</Text>
  )
}
