import React from 'react'
import { ValueColorWrapper } from './ValueColorWrapper'

export const PercentCellWithColor: React.FC<{
  value?: number
}> = ({ value }) => {
  const percent =
    value != null ? `${value === 0 ? '0' : value?.toFixed(2)}%` : '--'

  return (
    <ValueColorWrapper value={value}>
      {!!value && value > 0 && '+'}
      {percent}
    </ValueColorWrapper>
  )
}
