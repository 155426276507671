import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash'
import { put, takeLatest } from 'redux-saga/effects'
import { call, race, take } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IHurdle } from '../../../../../../../../../api/datahub'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { AppState } from '../../../../../../../../../store'
import { hurdleDeleteActions } from '../../../store/hurdleDelete'
import { hurdlePatchActions } from '../../../store/hurdlePatch'
import { hurdlePostActions } from '../../../store/hurdlePost'
import { hurdleEditFormActions } from './hurdleEditForm'

const OPEN = '@modules/@teams/@hurdleEditPanel/OPEN'
const CLOSE = '@modules/@teams/@hurdleEditPanel/CLOSE'
const SUBMIT = '@modules/@teams/@hurdleEditPanel/SUBMIT'
const FAILURE = '@modules/@teams/@hurdleEditPanel/FAILURE'
const SUCCESS = '@modules/@teams/@hurdleEditPanel/SUCCESS'

export const hurdleEditPanelActions = {
  open: createAction(OPEN)(),
  close: createAction(CLOSE)(),
  submit: createAction(SUBMIT)<IHurdle>(),
  failure: createAction(FAILURE)<Error>(),
  success: createAction(SUCCESS)()
}

export type hurdleEditPanelActionTypes = ActionType<
  typeof hurdleEditPanelActions
>

export interface IHurdleEditPanelState {
  isOpen?: boolean
  loading?: boolean
  error?: Error
}

const initialState: IHurdleEditPanelState = {}

export const hurdleEditPanelReducer = createReducer<
  IHurdleEditPanelState,
  hurdleEditPanelActionTypes
>(initialState)
  .handleAction(hurdleEditPanelActions.open, () => ({
    ...initialState,
    isOpen: true
  }))
  .handleAction(hurdleEditPanelActions.close, () => ({
    ...initialState,
    isOpen: false
  }))
  .handleAction(hurdleEditPanelActions.submit, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(hurdleEditPanelActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))
  .handleAction(hurdleEditPanelActions.success, (state) => ({
    ...state,
    loading: false
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.hurdleEdit
    .hurdleEditPanel

export const getIsHurdleEditPanelOpen = flow(
  rootSelector,
  ({ isOpen }) => isOpen
)

export const getIsHurdleEditPanelLoading = flow(
  rootSelector,
  ({ loading }) => loading
)

export const getHurdleEditPanelError = flow(rootSelector, ({ error }) => error)

const onSubmit = function* (
  action: ReturnType<typeof hurdleEditPanelActions.submit>
) {
  const hurdleId = action.payload.hurdleId
  const shouldPatch = !!hurdleId && !action?.payload?.measurements?.length
  const shouldDelete = hurdleId && action?.payload?.measurements

  if (shouldPatch) {
    yield put(hurdlePatchActions.request(action.payload))
    const { success, failure } = yield* race({
      success: take(hurdlePatchActions.success),
      failure: take(hurdlePatchActions.failure)
    })

    if (failure || !success) {
      yield put(
        hurdleEditPanelActions.failure(
          failure?.payload || new Error('An unknown error occurred')
        )
      )
      return
    }
    if (success) {
      yield call(pushNotification, {
        message: 'Successfully submitted hurdle.',
        type: MessageBarType.success
      })
    }
  }

  if (!shouldPatch) {
    yield put(hurdlePostActions.request(action.payload))

    const { success, failure } = yield* race({
      success: take(hurdlePostActions.success),
      failure: take(hurdlePostActions.failure)
    })

    if (failure || !success) {
      yield put(
        hurdleEditPanelActions.failure(
          failure?.payload || new Error('An unknown error occurred')
        )
      )
      return
    }
    if (success) {
      yield call(pushNotification, {
        message: 'Successfully submitted hurdle.',
        type: MessageBarType.success
      })
    }
  }

  if (shouldDelete) {
    yield put(hurdleDeleteActions.request(hurdleId))
    const { deleteFailure } = yield* race({
      deleteSuccess: take(hurdleDeleteActions.success),
      deleteFailure: take(hurdleDeleteActions.failure)
    })
    if (deleteFailure) {
      yield put(
        hurdleEditPanelActions.failure(
          deleteFailure?.payload || new Error('An unknown error occurred')
        )
      )
      return
    }
  }

  yield put(hurdleEditPanelActions.close())
  yield put(hurdleEditFormActions.reset())
}

const onClose = function* () {
  yield put(hurdleEditFormActions.reset())
}

export const hurdleEditPanelSagas = [
  () => takeLatest(hurdleEditPanelActions.submit, onSubmit),
  () => takeLatest(hurdleEditPanelActions.close, onClose)
]
