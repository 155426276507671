import { css } from '@emotion/react'
import {
  DirectionalHint,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { maskAccountNumber } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { FC, useMemo } from 'react'

const getClasses = () => ({
  scrollCss: css({
    maxHeight: 180,
    overflow: 'auto',
    fontSize: 12,
    paddingRight: 2
  }),
  moreLabelCss: css({
    cursor: 'pointer',
    color: 'rgb(0, 120, 212)'
  })
})

interface IShowDetailsProps {
  value: string[]
  masked?: true
}

export const ShowDetails: FC<IShowDetailsProps> = ({ value, masked }) => {
  const tooltipId = useId('tooltip')
  const classes = useMemo(() => getClasses(), [])
  if (value == null || !value.length) {
    return '--'
  }
  //const targetMore = createRef<HTMLSpanElement>()
  const show = 3
  const maskedItems = masked
    ? value.map((item) => maskAccountNumber(item))
    : [...value]
  const maskedItemsSorted = [...maskedItems].sort()
  const count = maskedItemsSorted.length
  const visibleItems = maskedItemsSorted.slice(0, show).join(', ')
  const hiddenItems = count > show ? maskedItemsSorted.slice(show, count) : []
  const moreLabel =
    count > show ? ` +\u00A0${count - show}\u00A0More` : undefined

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <div css={classes.scrollCss}>
        {hiddenItems.map((item, i) => (
          <div key={i}>{item}</div>
        ))}
      </div>
    )
  }
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: 'inline-block' }
  }

  return (
    <>
      {visibleItems}
      {moreLabel ? (
        <>
          {' '}
          <TooltipHost
            tooltipProps={tooltipProps}
            delay={TooltipDelay.zero}
            id={tooltipId}
            directionalHint={DirectionalHint.bottomCenter}
            styles={hostStyles}
          >
            <span css={classes.moreLabelCss}>{moreLabel}</span>
          </TooltipHost>
        </>
      ) : null}
    </>
  )
}
