import { ISystemUser } from 'api/dynamics'
import { stringify } from 'query-string'
import { createSelector } from 'reselect'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { dynamicsApi } from 'store/api/dynamics'

export interface INextGenCouncilMember {
  rcm_nextgencouncilmemberid?: string
  rcm_familynetworth?: string
  rcm_individualnetworth?: string
  rcm_legalentityid?: string
  rcm_name?: string
  rcm_additionalfamilyassets?: number
  rcm_rcm_nextgenxref_member?: INextGenCouncilMemberHousehold[]
  rcm_Advisor?: ISystemUser
  rcm_familyt12revenue?: number
}

export interface INextGenCouncilMemberHousehold {
  rcm_householdid?: string
}

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const nextGenCouncilApi = dynamicsApi.injectEndpoints({
  endpoints: (builder) => ({
    getNextGenCouncilConfig: builder.query<
      IOdataResult<INextGenCouncilMember> | undefined,
      void
    >({
      query: () => ({
        url: 'rcm_nextgencouncilmembers',
        params: {
          $select: [
            'rcm_name',
            'rcm_legalentityid',
            'rcm_familynetworth',
            'rcm_individualnetworth',
            'rcm_additionalfamilyassets',
            'rcm_familyt12revenue'
          ],
          $expand: [
            'rcm_Advisor($select=fullname)',
            'rcm_rcm_nextgenxref_member($select=rcm_householdid)'
          ]
        },
        paramsSerializer
      }),

      keepUnusedDataFor: 60 * 60 * 2
    })
  })
})

export const { useGetNextGenCouncilConfigQuery } = nextGenCouncilApi
const selectNextGenCouncilConfigApiState =
  nextGenCouncilApi.endpoints.getNextGenCouncilConfig.select()
export const selectNextGenCouncilConfig = createSelector(
  selectNextGenCouncilConfigApiState,
  ({ data }) => data
)
