import { IModuleDefinition } from 'modules/types'
import { GainAndLossesContainer } from './GainAndLossesContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/gains-and-losses/*',
    private: true,
    component: GainAndLossesContainer
  },
  name: 'GainAndLosses'
}

export default moduleDefinition
