const regex = /[^A-Za-z0-9]+/g
export const maskAccountNumber = (
  text?: string,
  unmaskedCount = 4,
  prefix = 'X'
) => {
  const unmasked = (text || '')
    .replace(regex, '')
    .slice(-unmaskedCount)
    .padStart(unmaskedCount, prefix)
  return `${prefix}${unmasked}`
}

// Pass array of account numbers
// Accounts are masked if masked is true
// Returns a list of account numbers separated by commas
// if the list is longer than max characters, the list is cropped at the last account that fit, and a message is appended
// Example 1: Uncropped Unmasked: 1234, 5678, 9012, 3456
// Example 2: Cropped and Masked: X234, X678 ... +2 more
export const getCroppedAccountList = (
  accounts: string[],
  masked: boolean,
  max: number,
  last = 4
) => {
  // mask account numbers if needed
  const accountsMasked = accounts.map((account) =>
    masked ? maskAccountNumber(account, last) : account
  )
  let count = 0
  let length = 0
  let cropped = false
  const accountList: string[] = []
  // push accounts to accountList array until string max length is reached
  accountsMasked.every((account) => {
    length += account.length + 1
    if (length < max) {
      accountList.push(account)
      count++
    } else {
      cropped = true
      return false
    }
    return true
  })
  let list = accountList.join(', ')
  if (cropped) {
    list += ` ... +${accounts.length - count} more`
  }
  if (accounts.length === 0) {
    list = 'None'
  }
  return list
}

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length)

  const bytes = atob(base64WithoutPrefix)
  let length = bytes.length
  const out = new Uint8Array(length)

  while (length--) {
    out[length] = bytes.charCodeAt(length)
  }

  return new Blob([out], { type: 'application/pdf' })
}
