export function isNotNullOrUndefined<T>(
  input: null | undefined | T
): input is T {
  return input != null
}

export function isNotNullOrFalse<T>(
  input: null | undefined | T | boolean
): input is T {
  return input != null && input !== false
}

export const isNotNullOrEmpty = (x: string | undefined): x is string => {
  return x != null && x?.toString()?.trim() !== ''
}

export const isDefined = <T>(input: T | undefined | null): input is T => {
  return typeof input !== 'undefined' && input !== null
}
