import { css, useTheme } from '@emotion/react'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs, groupBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { Dropdown, ISelectableOption } from '../../components/shared'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import Toggle from '../../components/shared/Toggle'
import { useGainLossesStore } from '../../features/GainLosses/gainLossesStore'
import { useYearRangeOptions } from '../../features/GainLosses/YearRangeOptionsHook'
import { Icon } from '../../features/Icons/Icon'
import {
  useFinancialsApiUtil,
  useGetRealizedGainLossSecurityQuery
} from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import { DETAIL } from '../../store/types'
import type { RealizedGainAndLossExportWorker } from './export'
import GainLossesDetailedViewTable from './feature/GainAndLossesDetail/GainLossesDetailViewTable'
import getColumns from './feature/GainAndLossesDetail/GainLossesDetailViewTableColumns'

export interface IArrayList {
  account?: string
  accounts?: IAccountList[]
  secName?: string
  symbol?: string
  description?: string
  quantity?: number
  acquisitionDate?: string
  acquisitionPrice?: number
  acquisitionCost?: number
  liquidationDate?: string
  liquidationPrice?: number
  liquidationAmount?: number
  shortTermGainLoss?: number
  longTermGainLoss?: number
  totalGainLoss?: number
  groupedAccountName?: string
}

export interface IAccountList {
  description?: string
  secName?: string
  quantity?: number
  acquisitionDate?: string
  acquisitionPrice?: number
  acquisitionCost?: number
  liquidationDate?: string
  liquidationAmount?: number
  shortTermGainLoss?: number
  longTermGainLoss?: number
  totalGainLoss?: number
  groupedAccountName?: string
  account?: string
  accounts?: IAccountList[]
}

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: 20
  }),
  iconStyle: css({
    width: 24,
    height: 46
  }),
  iconContainer: css({
    width: 24,
    height: 34,
    display: 'flex',
    alignItems: 'center'
  })
})
const GainLossesIncomeDetail: React.FC = () => {
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()

  const { yearRangeOptions } = useYearRangeOptions(apiContextAccounts)

  const {
    selectedFinancialYear,
    setSelectedFinancialYear,
    isTaxableAccounts,
    setIsTaxableAccounts,
    searchText,
    setSearchText
  } = useGainLossesStore()

  const onDateRangeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ISelectableOption<any>
  ) => {
    if (option) {
      setSelectedFinancialYear(option.key as number)
    }
  }

  const skip = !apiContextAccounts?.length
  const { data, isLoading, isFetching, isUninitialized, isError, error } =
    useGetRealizedGainLossSecurityQuery(
      skip
        ? skipToken
        : {
            accounts: apiContextAccounts,
            financialYear: selectedFinancialYear,
            taxable: isTaxableAccounts
          }
    )
  const gainLossesIncomeData = skip || isError ? undefined : data
  const columns = useMemo(() => getColumns(''), [])

  const getAccountGroupingData = (
    groupData: { [key: string]: DETAIL[] },
    account: { description: string; secName: string }
  ): IArrayList[] => {
    const securityAccountList: DETAIL[] = groupData[account?.description] || []

    const groupedDataList =
      securityAccountList?.map((data: DETAIL) => ({
        description: account.description,
        secName: account.secName,
        quantity: data.NUMSHRS,
        acquisitionDate: data.DTAQD,
        acquisitionPrice: data.COSTBASIS / data.NUMSHRS,
        acquisitionCost: data.COSTBASIS,
        liquidationDate: data.DTSALE,
        liquidationPrice: undefined,
        liquidationAmount: data.STKBNDAMT,
        shortTermGainLoss: data.SHRTGAINLOSS,
        longTermGainLoss: data.LNGGAINLOSS,
        totalGainLoss: data.PROFIT,
        groupedAccountName: data.ACCT
      })) || []

    return groupedDataList
  }

  const calculateGroupedAccountTotal = (
    data: IArrayList[],
    account: { description: string; secName: string }
  ) => {
    const uniqueAccountList = [
      ...new Set(data.map((val) => val.groupedAccountName))
    ]
    const updatedAccountList: IArrayList[] = []

    uniqueAccountList?.forEach((accountName?: string) => {
      const selectedAccountList = data.filter(
        (value) => value.groupedAccountName === accountName
      )
      if (selectedAccountList.length > 0) {
        let totalQuantity = 0
        let totalAcquisitionCost = 0
        let totalLiquidationAmount = 0
        let totalGainLoss = 0
        let shortTermGainLoss = 0
        let longTermGainLoss = 0

        selectedAccountList?.forEach((value: IArrayList) => {
          totalQuantity = totalQuantity + (value?.quantity || 0)
          totalAcquisitionCost =
            totalAcquisitionCost + (value.acquisitionCost || 0)
          totalLiquidationAmount =
            totalLiquidationAmount + (value.liquidationAmount || 0)
          totalGainLoss = totalGainLoss + (value.totalGainLoss || 0)
          shortTermGainLoss = shortTermGainLoss + (value.shortTermGainLoss || 0)
          longTermGainLoss = longTermGainLoss + (value.longTermGainLoss || 0)
          value.account = accountName
        })

        updatedAccountList.push({
          description: account.description,
          secName: account.secName,
          account: accountName,
          accounts: selectedAccountList,
          quantity: totalQuantity,
          acquisitionDate: undefined,
          acquisitionPrice: undefined,
          acquisitionCost: totalAcquisitionCost,
          liquidationDate: undefined,
          liquidationPrice: undefined,
          liquidationAmount: totalLiquidationAmount,
          shortTermGainLoss: shortTermGainLoss,
          longTermGainLoss: longTermGainLoss,
          totalGainLoss: totalGainLoss,
          groupedAccountName: accountName
        })
      }
    })
    return updatedAccountList
  }

  const gridData = useMemo(() => {
    const detailList: DETAIL[] = []
    let groupedDetailedList: Record<string, DETAIL[]> = {}
    gainLossesIncomeData?.ITEMSUMMARY?.forEach((val) => {
      val.DETAILSUMMARY?.forEach((val2) => {
        val2.DETAIL?.forEach((val3) => {
          detailList.push(val3)
        })
      })
    })

    groupedDetailedList = groupBy(detailList, (val) => val.SECDESCRIPTION)
    const uniqueSecurityList = [
      ...new Set<string>(
        detailList?.map((val): string => `${val.SECDESCRIPTION}:${val.SECNAME}`)
      )
    ].map((val: string) => {
      const securityNameParts = val.split(':')
      return {
        description: securityNameParts[0],
        secName: securityNameParts[1]
      }
    })

    const arrayList: IArrayList[] = []
    uniqueSecurityList?.forEach(
      (val: { description: string; secName: string }) => {
        const securityAccountList: any = getAccountGroupingData(
          groupedDetailedList,
          val
        )

        let quantity = 0
        let acquisitionCost = 0
        let liquidationAmount = 0
        let shortTermGainLoss = 0
        let longTermGainLoss = 0
        let totalGainLoss = 0
        let liquidationDate = undefined
        let acquisitionDate = undefined
        let acquisitionPrice = undefined

        securityAccountList?.forEach((val: IAccountList) => {
          quantity = quantity + (val.quantity || 0)
          acquisitionCost = acquisitionCost + (val.acquisitionCost || 0)
          liquidationAmount = liquidationAmount + (val.liquidationAmount || 0)
          shortTermGainLoss = shortTermGainLoss + (val.shortTermGainLoss || 0)
          longTermGainLoss = longTermGainLoss + (val.longTermGainLoss || 0)
          totalGainLoss = totalGainLoss + (val.totalGainLoss || 0)
        })

        if (securityAccountList?.length === 1) {
          liquidationDate = securityAccountList[0].liquidationDate
          acquisitionDate = securityAccountList[0].acquisitionDate
          acquisitionPrice = securityAccountList[0].acquisitionPrice
        }

        arrayList.push({
          description: val?.description,
          secName: val?.secName,
          accounts: calculateGroupedAccountTotal(securityAccountList, val),
          quantity: quantity,
          acquisitionDate: acquisitionDate,
          acquisitionPrice: acquisitionPrice,
          acquisitionCost: acquisitionCost,
          liquidationDate: liquidationDate,
          liquidationPrice: undefined,
          liquidationAmount: liquidationAmount,
          shortTermGainLoss: shortTermGainLoss,
          longTermGainLoss: longTermGainLoss,
          totalGainLoss: totalGainLoss
        })
      }
    )

    let shortTermGainLossOverallTotal = 0
    let longTermGainLossOverallTotal = 0
    let totalGainLossOverallTotal = 0
    arrayList?.forEach((record: IArrayList) => {
      shortTermGainLossOverallTotal =
        shortTermGainLossOverallTotal + (record?.shortTermGainLoss || 0)
      longTermGainLossOverallTotal =
        longTermGainLossOverallTotal + (record?.longTermGainLoss || 0)
      totalGainLossOverallTotal =
        totalGainLossOverallTotal + (record?.totalGainLoss || 0)
    })

    return arrayList
  }, [gainLossesIncomeData?.ITEMSUMMARY])

  const exportToExcel = useCallback(async () => {
    if (!gridData) {
      return
    }
    const columnDef = columns.map((x) => ({
      id: x.id || '',
      header: x.header as string
    }))
    const exportData = gridData
      ?.flatMap((x) => x.accounts?.flatMap((x) => x.accounts))
      ?.filter(isNotNullOrUndefined)
    const mappedExportData = exportData?.map((x, i) =>
      fromPairs<any>(
        columns.map((column) => [column.id || '', column.accessorFn(x, i)])
      )
    )

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportRealizedGainAndLoss } =
      wrap<RealizedGainAndLossExportWorker>(worker)

    const result = await exportRealizedGainAndLoss(mappedExportData, columnDef)
    const filename = `Gain Loss Summary ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, gridData])

  const classes = useMemo(() => getClasses(), [])
  const { invalidateTags } = useFinancialsApiUtil()
  const theme = useTheme()

  return (
    <>
      <div css={classes.container}>
        <DetailsNavigator />
        <div css={{ width: 160 }}>
          <div css={{ fontSize: 14, fontWeight: 500 }}>Year</div>
          <Dropdown
            options={yearRangeOptions}
            selectedKey={selectedFinancialYear}
            onChange={onDateRangeChange}
          />
        </div>
        <div
          css={{
            display: 'flex',
            columnGap: 5,
            alignItems: 'center',
            marginBottom: '9px'
          }}
        >
          <div>All Accounts</div>
          <Toggle
            defaultChecked={isTaxableAccounts}
            onChange={() => {
              setIsTaxableAccounts(!isTaxableAccounts)
            }}
          />
          <div>Taxable Accounts</div>
        </div>
        <div css={{ marginLeft: 'auto' }}>
          <Searchbox searchText={searchText} onChange={setSearchText} />
        </div>
        <div css={classes.iconContainer}>
          <Icon
            type="Refresh"
            width={24}
            height={24}
            onClick={() => invalidateTags(['gainandlosss'])}
            color={theme.colors.extraBlue2}
          />
        </div>
        <div
          css={[
            classes.iconContainer,
            {
              opacity: gainLossesIncomeData ? 1 : 0.5
            }
          ]}
        >
          <Icon
            type="Download"
            width={24}
            height={24}
            onClick={exportToExcel}
            color={theme.colors.extraBlue2}
          />
        </div>
      </div>
      <div css={{ marginTop: 24 }}>
        <HorizontalScrollContainer>
          <GainLossesDetailedViewTable
            isLoading={isLoading || isFetching}
            isUninitialized={isUninitialized}
            error={error}
            data={gridData}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </HorizontalScrollContainer>
      </div>
      <div css={{ fontSize: 10 }}>
        Note: Rockefeller Accounts Only. The information herein is for
        informational purposes only. For tax reporting or calculations, you
        should only rely on your annual Tax Reporting Statement consisting of
        various Forms of 1099 (or any other appropriate tax form). Rockefeller
        Financial LLC cannot guarantee that the information herein is accurate,
        complete, or timely. Rockefeller Financial LLC does not provide legal or
        tax advice. You should consult with a tax or legal professional to
        address your particular situation. Taxable Accounts is based on the
        account data and not individual investments within the accounts.
        External accounts or those from other custodians may not be identifiable
        as Taxable and not displayed.
      </div>
    </>
  )
}

export default GainLossesIncomeDetail
