import { ISelectableOption } from '@fluentui/react'
import { IRdot360Account } from '../../store/rdot360Context'

export function mask(str: string, last = 4) {
  return 'X' + str.slice(-last)
}

export const activityDetailColumns = {
  activityDate: 'Activity Date',
  settlementDate: 'Settlement Date',
  entryDate: 'Entry Date',
  account: 'Account',
  description: 'Description',
  type: 'Type',
  symbol: 'Symbol',
  cusip: 'CUSIP',
  symbolCusip: 'Symbol / CUSIP',
  quantity: 'Quantity',
  price: 'Price',
  amount: 'Amount',
  nickName: 'Nickname',
  registrationType: 'Registration Type'
}

export const activityDropdownOptions: ISelectableOption[] = [
  { key: 'BY', text: 'Buys' },
  { key: 'SL', text: 'Sells' },
  { key: 'DV', text: 'Dividends & Interest' },
  { key: 'FE', text: 'Fees & Interest Charged' },
  { key: 'DP', text: 'Deposits / Contributions' },
  { key: 'WD', text: 'Withdrawals' },
  { key: 'JN', text: 'Journal Entries' },
  { key: 'CA', text: 'Corporate Actions' },
  { key: 'EX', text: 'Exchanges/Transfers' },
  { key: 'ZZ', text: 'Other' }
]

export const getSelectedAccounts = (
  selectedAccounts: IRdot360Account[],
  masked: boolean
) => {
  // get array of account numbers
  const accountsAll = selectedAccounts.map(
    (account) => account.CustodyAccount || ''
  )
  // mask account numbers if needed
  const accounts = masked
    ? accountsAll.map((account) => mask(account))
    : accountsAll
  let count = 0
  let length = 0
  const max = 220
  let cropped = false
  const accountNumbers: string[] = []
  // push accounts to accountNumbers array until string max length is reached
  accounts.every((account) => {
    length += account.length + 1
    if (length < max) {
      accountNumbers.push(account)
      count++
    } else {
      cropped = true
      return false
    }
    return true
  })
  let selected = accountNumbers.join(', ')
  if (cropped) {
    selected += ` ... +${selectedAccounts.length - count} more`
  }
  if (selectedAccounts.length === 0) {
    selected = 'None'
  }
  return selected
}
