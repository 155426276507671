export const GainLossDetailColumnNames = {
  description: 'Description',
  symbol: 'Symbol',
  symbolDescription: 'Symbol / Description',
  account: 'Account',
  quantity: 'Quantity',
  acquisitionDate: 'Acquisition Date',
  acquisitionPrice: 'Acquisition Price',
  acquisitionCost: 'Acquisition Cost',
  liquidationDate: 'Liquidation Date',
  liquidationAmount: 'Net Proceeds',
  shortTermGainLoss: 'Short Term Gain/Loss',
  longTermGainLoss: 'Long Term Gain/Loss',
  totalGainLoss: 'Total Gain/Loss'
}
