import {
  Checkbox,
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IColumn,
  IPlainCardProps,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from '@fluentui/react'
import { keyBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IEnhancedClientProfileResponse } from 'store/api/datahub'
import { IAccount } from '../../api/account.types'
import { DataTable } from '../../shared/components/DataTable'
import { isNotNullOrUndefined } from '../../shared/guards'
import { TextCell } from '../Lists/core/components/TextCell'
import { AccountNumberCell } from '../Lists/shared/AccountNumberCell'
import { getAccountLinkingLinkedAccounts } from './store/accountLinkingValidation'
const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

const multiCustodianSources = ['fsa', 'first rate']

export const AccountLinkingAccountList: React.FC<{
  accounts?: IAccount[]
  selectedAccounts?: string[]
  onSelectionChanged?: (accounts?: string[]) => void
  loading?: boolean
  enhancedProfile?: IEnhancedClientProfileResponse
}> = ({
  accounts = [],
  loading = false,
  selectedAccounts = [],
  onSelectionChanged,
  enhancedProfile
}) => {
  const linkedAccounts = useSelector(getAccountLinkingLinkedAccounts)
  const isPilotProfile =
    enhancedProfile?.profile?.multiCustodianPilotInd === 'P'
  const isNfsProfile =
    !enhancedProfile ||
    enhancedProfile?.profile?.wsPortalUserId?.startsWith('6611')

  const selectedAccountLookup = useMemo(
    () =>
      selectedAccounts.reduce(
        (a, x) => ((a[x] = true), a),
        {} as Record<string, boolean | undefined>
      ),
    [selectedAccounts]
  )

  const onAccountCheckboxChange = useCallback(
    (account: string, checked?: boolean) => {
      const lookupCopy = { ...selectedAccountLookup }
      lookupCopy[account] = checked
      const selected = Object.entries(lookupCopy)
        .filter(([, checked]) => checked)
        .map(([account]) => account)

      onSelectionChanged?.(selected)
    },
    [onSelectionChanged, selectedAccountLookup]
  )

  const linkedAccountLookup = useMemo(
    () =>
      keyBy(
        [
          linkedAccounts,
          (enhancedProfile?.relatedAccounts || [])
            .filter((x) => x.isInProfile)
            .map((x) => x.accountKey)
        ].flat()
      ),
    [enhancedProfile, linkedAccounts]
  )

  const getPlainCardProps = useCallback(
    (
      message = 'Account is already linked to Selected Client'
    ): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <MessageBar messageBarType={MessageBarType.error}>{message}</MessageBar>
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: false
      }
    }),
    []
  )

  const columns: IColumn[] = useMemo(
    () =>
      [
        onSelectionChanged && {
          ...defaultColumn,
          name: '',
          key: 'approve',
          maxWidth: 20,
          onRender: (account: IAccount) => {
            const { accountkey } = account
            if (!accountkey) {
              return null
            }

            const isAlreadyLinked = !!linkedAccountLookup[accountkey]
            const accountSource = account.Accountsource?.toLowerCase() || ''
            const isNfsAccount = accountSource === 'nfs'
            const hasPartyId = !!enhancedProfile?.profile?.partyId

            const canLink =
              (isNfsProfile && isNfsAccount) ||
              (isPilotProfile && accountSource === 'rockit') ||
              multiCustodianSources.includes(accountSource)

            const [message] = [
              [isAlreadyLinked, 'Account is already linked to Selected Client'],
              [!canLink, 'Account cannot be linked to Selected Client.'],
              [
                !hasPartyId && !isNfsAccount,
                `The account at other custodian can't be linked at this time due to missing Party ID.  Please contact Service Desk to have this corrected.`
              ]
            ]
              .filter(([condition]) => condition)
              .map(([, message]) => message as string)

            const disableSelection = !!message

            return (
              <HoverCard
                plainCardProps={getPlainCardProps(message)}
                cardOpenDelay={0}
                instantOpenOnClick={false}
                type={HoverCardType.plain}
                shouldBlockHoverCard={() => !disableSelection}
              >
                <Checkbox
                  checked={!!selectedAccountLookup[accountkey]}
                  onChange={(_, checked) =>
                    account.id && onAccountCheckboxChange(accountkey, checked)
                  }
                  disabled={disableSelection}
                />
              </HoverCard>
            )
          }
        },
        {
          ...defaultColumn,
          name: 'Account',
          key: 'account',
          maxWidth: 180,
          onRender: (account: IAccount) => (
            <AccountNumberCell account={account} />
          )
        },
        {
          ...defaultColumn,
          key: 'name',
          name: 'Legal Entity Name',
          maxWidth: 180,
          onRender: ({ LegalEntityName }: IAccount) => (
            <TextCell>{LegalEntityName}</TextCell>
          )
        },
        {
          ...defaultColumn,
          key: 'household',
          maxWidth: 180,
          name: 'Household',
          onRender: ({ householdName }: IAccount) => (
            <TextCell>{householdName}</TextCell>
          )
        },
        {
          ...defaultColumn,
          key: 'rep',
          maxWidth: 20,
          name: 'Rep Code',
          onRender: ({ ClientAdvisorID }: IAccount) => (
            <TextCell>{ClientAdvisorID}</TextCell>
          )
        }
      ].filter(isNotNullOrUndefined),
    [
      enhancedProfile?.profile?.partyId,
      getPlainCardProps,
      isNfsProfile,
      isPilotProfile,
      linkedAccountLookup,
      onAccountCheckboxChange,
      onSelectionChanged,
      selectedAccountLookup
    ]
  )
  return (
    <>
      <div style={{ height: '3px' }}>{loading && <ProgressIndicator />}</div>
      <DataTable columns={columns} items={accounts} shimmerLines={5} />
    </>
  )
}
