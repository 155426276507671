import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Selection,
  IColumnReorderOptions
} from '@fluentui/react'
import { debounce } from 'lodash'
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { SearchResponseType } from '../../../../api/common.types'
import { DataTable } from '../../../../shared/components/DataTable'
import { HorizontalScrollContainerWrapper } from '../../../../shared/components/HorizontalScrollContainerWrapper'
import { isNotNullOrUndefined } from '../../../../shared/guards'
import { IColumnDefinition } from '../contracts/IColumnDefinition'
import { IListsOrderBy } from '../contracts/IListsOrderBy'

export interface IListsCellComponentProps<T extends SearchResponseType> {
  columnDefinition: IColumnDefinition
  item: T
}

export type IListsCellComponent<T extends SearchResponseType> = (
  props: IListsCellComponentProps<T>
) => React.ReactElement<IListsCellComponentProps<T>>

export interface IListsDataTableComponentProps<T extends SearchResponseType> {
  items?: T[]
  columns: IColumnDefinition[]
  cell: IListsCellComponent<T>
  orderBy?: IListsOrderBy
  onColumnClick?: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: IColumnDefinition
  ) => void
  onResize?: (newWidths: Record<string, number>) => void
  stickyHeaderOffset?: number
  numStickyColumns?: number
  enableShimmer?: boolean
  enablePreviewColumns: boolean
  layoutMode?: DetailsListLayoutMode
  selectionMode?: SelectionMode
  selection?: Selection
  columnReorderOptions?: IColumnReorderOptions
}

export const ListsDataTableComponent: <T extends SearchResponseType>(
  props: IListsDataTableComponentProps<T>
) => React.ReactElement<IListsDataTableComponentProps<T>> = ({
  items,
  columns = [],
  cell,
  orderBy,
  onColumnClick,
  stickyHeaderOffset,
  numStickyColumns,
  onResize,
  enableShimmer,
  enablePreviewColumns,
  layoutMode = DetailsListLayoutMode.justified,
  selectionMode,
  selection,
  columnReorderOptions
}) => {
  const columnKeyCount = useRef(0)
  useEffect(() => {
    columnKeyCount.current++
  })
  const CellComponent = memo(cell)
  const pendingResizeMap = useRef<Record<string, number>>({})
  const fireOnResizeDebounced = useMemo(
    () =>
      debounce(() => {
        if (!pendingResizeMap.current) {
          return
        }

        onResize?.(pendingResizeMap.current)
        pendingResizeMap.current = {}
      }, 1000),
    [onResize]
  )

  useEffect(() => {
    return fireOnResizeDebounced.cancel()
  }, [fireOnResizeDebounced])

  const dataTableColumns = useMemo(() => {
    return columns
      .map((x) => {
        if (!enablePreviewColumns && x.preview) {
          return null
        }

        const width = x.width || 150

        const dataTableColumn: IColumn & { cid?: string } = {
          cid: x.id,
          key: `${x.id}-${columnKeyCount.current}`,
          name: x.name,
          headerClassName:
            x.className ||
            (x.type === 'date' || x.type === 'number' || x.type === 'date-only'
              ? 'align-right'
              : undefined),
          className:
            x.className ||
            (x.type === 'date' || x.type === 'number' || x.type === 'date-only'
              ? 'align-right'
              : undefined),
          minWidth: width,
          maxWidth: width,
          isCollapsable: x.collapsible || true,
          isResizable: x.resizable != null ? x.resizable : true,
          onRender: (item) => (
            <CellComponent columnDefinition={x} item={item} />
          ),
          columnActionsMode: ColumnActionsMode.clickable,
          onColumnClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) =>
            onColumnClick && onColumnClick(ev, x),
          isSorted: orderBy?.columnId === x.id,
          isSortedDescending: orderBy?.direction === 'desc'
        }
        return dataTableColumn
      })
      .filter(isNotNullOrUndefined)
  }, [columns, enablePreviewColumns, orderBy, CellComponent, onColumnClick])

  const onColumnResize = useCallback(
    (column?: IColumn & { cid?: string }, newWidth?: number) => {
      if (!column?.cid || !newWidth) {
        return
      }

      pendingResizeMap.current[column.cid] = newWidth
      fireOnResizeDebounced()
    },
    [fireOnResizeDebounced]
  )

  return (
    <HorizontalScrollContainerWrapper layoutMode={layoutMode}>
      <DataTable
        items={items}
        columns={dataTableColumns}
        stickyHeaderOffset={stickyHeaderOffset}
        numStickyColumns={
          layoutMode === DetailsListLayoutMode.fixedColumns
            ? numStickyColumns
            : undefined
        }
        layoutMode={layoutMode}
        enableShimmer={enableShimmer}
        selectionMode={selectionMode}
        selection={selection}
        onColumnResize={onColumnResize}
        columnReorderOptions={columnReorderOptions}
      />
    </HorizontalScrollContainerWrapper>
  )
}
