import { Link, Stack, Text } from '@fluentui/react'
import React from 'react'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { useDispatch } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { IPosition } from '../../../api/position.types'
import { navigationActions } from '../../../store/ui/actions'
import { DateCell } from '../core/components/DateCell'
import { IListsCellComponent } from '../core/components/ListsDataTable'
import { NumberCell } from '../core/components/NumberCell'
import { PercentCell } from '../core/components/PercentCell'
import { TextCell } from '../core/components/TextCell'
import { USDCell } from '../core/components/USDCell'
import { AccountNumberCellContainer } from '../shared/AccountNumberCellContainer'
import { AdjustedMarketValueCell } from './AdjustedMarketValueCell'
import { PositionColumnIds } from './ColumnDefinitions'
import { HouseholdNameCell } from './HouseholdNameCell'

export const PositionCell: IListsCellComponent<IPosition> = ({
  columnDefinition,
  item
}) => {
  const Cell = CellComponents[columnDefinition.id]
  return Cell && <Cell position={item} />
}

const CellComponents: {
  [key: string]: React.FC<{ position: IPosition }>
} = {
  [PositionColumnIds.accountNumber]: ({
    position: {
      accountnumber,
      registrationType,
      AdvisorAddedNickName,
      shortName,
      accountKey,
      householdId
    }
  }) => (
    <AccountNumberCellContainer
      account={{
        CustodyAccount: accountnumber,
        id: accountKey,
        AdvisorAddedNickName: AdvisorAddedNickName,
        Shortname: shortName,
        registrationtype: registrationType,
        householdId: householdId
      }}
      menu={null}
    />
  ),
  [PositionColumnIds.symbol]: ({ position }) => (
    <TextCell>{position.symbol}</TextCell>
  ),
  [PositionColumnIds.description]: ({ position }) => (
    <TextCell>{position.description}</TextCell>
  ),
  [PositionColumnIds.cusip]: ({ position }) => {
    const dispatch = useDispatch()
    const structProd = position.securitytype
    if (structProd === 'STRUCTURED PRODUCTS') {
      return (
        <Link
          onClick={() => {
            dispatch(navigationActions.launchSimon(position.cusip || ''))
          }}
        >
          {position.cusip}
        </Link>
      )
    } else {
      return <TextCell>{position.cusip}</TextCell>
    }
  },
  [PositionColumnIds.marketvalue]: ({ position }) => (
    <USDCell value={position.marketvalue} fractionDigits={0} />
  ),
  [PositionColumnIds.securitytype]: ({ position }) => (
    <TextCell>{position.securitytype}</TextCell>
  ),
  [PositionColumnIds.assetType]: ({ position }) => (
    <TextCell>{position.assetClassLevel1}</TextCell>
  ),
  [PositionColumnIds.assetTypeDetail]: ({ position }) => (
    <TextCell>{position.assetClassLevel3}</TextCell>
  ),
  [PositionColumnIds.securitySubtype]: ({ position }) => (
    <TextCell>{position.securitySubtype}</TextCell>
  ),
  [PositionColumnIds.underlyingoptionquantity]: ({ position }) => (
    <NumberCell value={position.underlyingoptionquantity} />
  ),
  [PositionColumnIds.tradedatequantity]: ({ position }) => (
    <NumberCell value={position.tradedatequantity} />
  ),
  [PositionColumnIds.unrealizedgainloss]: ({ position }) => (
    <USDCell value={position.unrealizedgainloss} fractionDigits={0} />
  ),
  [PositionColumnIds.costBasis]: ({ position }) => (
    <USDCell value={position.costBasisAmount} fractionDigits={0} />
  ),
  [PositionColumnIds.price]: ({ position }) => (
    <USDCell value={position.price} />
  ),
  [PositionColumnIds.accruedinterest]: ({ position }) => (
    <USDCell value={position.accruedinterest} />
  ),
  [PositionColumnIds.accruedIncome]: ({ position }) => (
    <USDCell value={position.accruedincome} />
  ),
  [PositionColumnIds.modelallocation]: ({ position }) => (
    <NumberCell value={position.modelallocation} />
  ),
  [PositionColumnIds.washsellindicator]: ({ position }) => (
    <TextCell>{position.washsellindicator}</TextCell>
  ),
  [PositionColumnIds.accountallocation]: ({ position }) => (
    <NumberCell value={position.accountallocation} />
  ),
  [PositionColumnIds.modelmember]: ({ position }) => (
    <TextCell>{position.modelmember}</TextCell>
  ),
  [PositionColumnIds.businesssegment]: ({ position }) => (
    <TextCell>{position.businesssegment}</TextCell>
  ),
  [PositionColumnIds.registeredrep]: ({ position }) => (
    <TextCell>{position.registeredrep}</TextCell>
  ),
  [PositionColumnIds.holdingdate]: ({ position }) =>
    position.holdingdate ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(position.holdingdate)}
        />
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.LastUpdatedAt]: ({ position }) =>
    position.LastUpdatedAt ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(position.LastUpdatedAt)}
        />
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.drift]: ({ position }) => (
    <NumberCell value={position.drift} />
  ),
  [PositionColumnIds.optioncontractid]: ({ position }) => (
    <TextCell>{position.optioncontractid}</TextCell>
  ),
  [PositionColumnIds.optioncallputind]: ({ position }) => (
    <TextCell>{position.optioncallputind}</TextCell>
  ),
  [PositionColumnIds.optionexpdate]: ({ position }) => (
    <DateCell value={position.optionexpdate} />
  ),
  [PositionColumnIds.optionsymbolid]: ({ position }) => (
    <TextCell>{position.optionsymbolid}</TextCell>
  ),
  [PositionColumnIds.partyname]: ({ position }) => (
    <TextCell>{position.LegalEntityName}</TextCell>
  ),
  [PositionColumnIds.custodianName]: ({ position }) => (
    <TextCell>{position?.CustodianName}</TextCell>
  ),
  [PositionColumnIds.custodianCode]: ({ position }) => (
    <TextCell>{position?.CustodianCode}</TextCell>
  ),
  [PositionColumnIds.custodianType]: ({ position }) => (
    <TextCell>{position?.CustodianType}</TextCell>
  ),
  [PositionColumnIds.advisorName]: ({ position }) => (
    <TextCell>{position?.advisorName}</TextCell>
  ),
  [PositionColumnIds.householdId]: ({ position }) => (
    <TextCell>{position?.householdId}</TextCell>
  ),
  [PositionColumnIds.householdName]: ({ position }) => (
    <HouseholdNameCell
      householdId={position.householdId}
      householdName={position.householdName}
    />
  ),
  [PositionColumnIds.nickname]: ({ position }) => (
    <TextCell>{position?.AdvisorAddedNickName}</TextCell>
  ),
  [PositionColumnIds.clientNickname]: ({ position }) => (
    <TextCell>{position?.nickname}</TextCell>
  ),
  [PositionColumnIds.shortname]: ({ position }) => (
    <TextCell>{position?.shortName}</TextCell>
  ),
  [PositionColumnIds.registrationType]: ({ position }) => (
    <TextCell>{position?.registrationType}</TextCell>
  ),
  [PositionColumnIds.DrawdownNonDrawdown]: ({ position }) => (
    <TextCell>{position?.DrawdownNonDrawdown || '--'}</TextCell>
  ),
  [PositionColumnIds.OriginalOrderAmount]: ({ position }) =>
    position.DrawdownNonDrawdown === 'NO' ? (
      <USDCell value={position?.OriginalOrderAmount} fractionDigits={0} />
    ) : (
      <>--</>
    ),
  [PositionColumnIds.CapitalCall]: ({ position }) =>
    position.DrawdownNonDrawdown === 'YES' ? (
      <USDCell value={position?.CapitalCall} fractionDigits={0} />
    ) : (
      <>--</>
    ),
  [PositionColumnIds.RemainingOriginalAmount]: ({ position }) =>
    position.DrawdownNonDrawdown === 'NO' ? (
      <USDCell value={position?.RemainingOriginalAmount} fractionDigits={0} />
    ) : (
      <>--</>
    ),
  [PositionColumnIds.Distributions]: ({ position }) => (
    <USDCell value={position?.Distributions} fractionDigits={0} />
  ),
  [PositionColumnIds.TotalValue]: ({ position }) => (
    <USDCell value={position?.TotalValue} fractionDigits={0} />
  ),
  [PositionColumnIds.capitalCommitment]: ({ position }) =>
    position.DrawdownNonDrawdown === 'YES' ? (
      <USDCell value={position?.capitalCommitment} fractionDigits={0} />
    ) : (
      <>--</>
    ),
  [PositionColumnIds.Unfunded_Value]: ({ position }) =>
    position.DrawdownNonDrawdown === 'YES' ? (
      <USDCell value={position?.Unfunded_Value} fractionDigits={0} />
    ) : (
      <>--</>
    ),
  [PositionColumnIds.netInvestmentGainLoss]: ({ position }) =>
    position.DrawdownNonDrawdown === 'NO' ? (
      <USDCell value={position?.UnrealizedGainOrloss} fractionDigits={0} />
    ) : (
      <>--</>
    ),

  [PositionColumnIds.symbolCusipDesc]: ({ position }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Text variant="small" nowrap={true}>
        {position.description}
      </Text>
      <Stack horizontal={true} tokens={{ childrenGap: 8 }}>
        <Text styles={{ root: { fontWeight: 'bold' } }} nowrap={true}>
          {position.symbol}
        </Text>
        <Text variant="small" nowrap={true}>
          {position.cusip}
        </Text>
      </Stack>
    </Stack>
  ),
  [PositionColumnIds.ValuationDate]: ({ position }) =>
    position.ENTRY_DATE ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(position.ENTRY_DATE)}
        />
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.originalOrderDate]: ({ position }) =>
    position.OriginalOrderDate ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(position.OriginalOrderDate)}
        />
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.vintageYear]: ({ position }) =>
    position.VintageYear ? (
      <TextCell>{position.VintageYear}</TextCell>
    ) : position.OriginalOrderDate ? (
      <Text nowrap={true}>
        {parseDateISOStringInLocalTimezone(
          position.OriginalOrderDate
        )?.getFullYear() || '--'}
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.tvpi]: ({ position }) => (
    <Text>
      {position.DrawdownNonDrawdown === 'YES' && position.CapitalCall ? (
        <>
          <FormattedNumber
            value={(position.TotalValue || 0) / position.CapitalCall}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          x
        </>
      ) : (
        <>--</>
      )}
    </Text>
  ),
  [PositionColumnIds.ai_divReinvest]: ({ position }) => (
    <TextCell>{position.DivReInvest}</TextCell>
  ),
  [PositionColumnIds.ai_cleanFundName]: ({ position }) => (
    <TextCell>{position.CleanFundName}</TextCell>
  ),
  [PositionColumnIds.ai_sponsorName]: ({ position }) => (
    <TextCell>{position.SponsorName}</TextCell>
  ),
  [PositionColumnIds.ai_dividends]: ({ position }) => (
    <USDCell value={position?.dividends} fractionDigits={0} />
  ),
  [PositionColumnIds.ai_dividends_cash]: ({ position }) => (
    <USDCell value={position?.DivCash} fractionDigits={0} />
  ),
  [PositionColumnIds.ai_dividends_reinvest]: ({ position }) => (
    <USDCell value={position?.DivReinvested} fractionDigits={0} />
  ),
  [PositionColumnIds.ai_totalReturnPercentage]: ({ position }) => (
    <Text>
      {position.DrawdownNonDrawdown === 'NO' &&
      position.TotalReturnPercentage != null ? (
        <>
          <FormattedNumber
            value={position.TotalReturnPercentage}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          %
        </>
      ) : (
        <>--</>
      )}
    </Text>
  ),
  [PositionColumnIds.ai_valuationReceivedDate]: ({ position }) =>
    position.ValuationReceivedDate ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(
            position.ValuationReceivedDate
          )}
        />
      </Text>
    ) : (
      <>--</>
    ),
  [PositionColumnIds.ai_expectedTiming]: ({ position }) => (
    <TextCell>{position.ExpectedTiming}</TextCell>
  ),
  [PositionColumnIds.ai_k1EstimateAvailable]: ({ position }) => (
    <TextCell>{position.K1EstimateAvailable}</TextCell>
  ),
  [PositionColumnIds.ai_taxReporting]: ({ position }) => (
    <TextCell>{position.TaxReporting}</TextCell>
  ),
  [PositionColumnIds.Source]: ({ position }) => (
    <TextCell>{position?.Source}</TextCell>
  ),
  [PositionColumnIds.RecallableDistributions]: ({ position }) => (
    <USDCell value={position?.RecallableDistributions} fractionDigits={0} />
  ),
  [PositionColumnIds.DPI]: ({ position }) => (
    <NumberCell value={position?.DPI} />
  ),
  [PositionColumnIds.NetIRRAnnualized]: ({ position }) => (
    <PercentCell value={position.IRRpercentage} />
  ),
  [PositionColumnIds.NetIRRCumulative]: ({ position }) => (
    <PercentCell value={position.NetIRRCumulative} />
  ),
  [PositionColumnIds.AnnualizedReturnPer]: ({ position }) => (
    <PercentCell value={position.AnnualizedReturnPer} />
  ),
  [PositionColumnIds.YieldPercentage]: ({ position }) => (
    <PercentCell value={position.YieldPercentage} />
  ),
  [PositionColumnIds.RVPI]: ({ position }) => (
    <>
      <NumberCell value={position?.RVPI} /> {!!position?.RVPI && 'x'}
    </>
  ),
  [PositionColumnIds.TVPI]: ({ position }) => (
    <>
      <NumberCell value={position?.TVPI} />
      {!!position?.TVPI && 'x'}
    </>
  ),
  [PositionColumnIds.PicMultiple]: ({ position }) => (
    <NumberCell value={position?.PicMultiple} />
  ),
  [PositionColumnIds.OnPlatform_OffPlatform]: ({ position }) => (
    <TextCell>{position?.OnPlatform_OffPlatform}</TextCell>
  ),
  [PositionColumnIds.ShortTermGain]: ({ position }) => (
    <USDCell value={position?.ShortTermGain} fractionDigits={0} />
  ),
  [PositionColumnIds.LongTermGain]: ({ position }) => (
    <USDCell value={position?.LongTermGain} fractionDigits={0} />
  ),
  [PositionColumnIds.SaleProceeds]: ({ position }) => (
    <USDCell value={position?.SaleProceeds} fractionDigits={0} />
  ),
  [PositionColumnIds.TotalIncome]: ({ position }) => (
    <USDCell value={position?.TotalIncome} fractionDigits={0} />
  ),
  [PositionColumnIds.AdjustedMarketValue]: ({ position }) => (
    <AdjustedMarketValueCell
      id={position?.id}
      adjustedMarketValue={position?.AdjustedMarketValue}
    />
  ),
  [PositionColumnIds.AdjustedTotalValue]: ({ position }) => (
    <USDCell value={position?.AdjustedTotalValue} fractionDigits={0} />
  ),
  [PositionColumnIds.ai_lastValuationValue]: ({ position }) => (
    <USDCell value={position?.LastValuationValue} fractionDigits={0} />
  ),
  [PositionColumnIds.AdjustedTVPI]: ({ position }) => (
    <>
      <NumberCell
        value={position?.AdjustedTVPI || undefined}
        fractionDigits={2}
      />
      {!!position?.AdjustedTVPI && <>x</>}
    </>
  ),
  [PositionColumnIds.RCMTVPI]: ({ position }) => (
    <>
      <NumberCell value={position?.RCMTVPI || undefined} fractionDigits={2} />
      {!!position?.RCMTVPI && <>x</>}
    </>
  ),
  [PositionColumnIds.assetClassLevel2]: ({ position }) => (
    <TextCell>{position?.assetClassLevel2}</TextCell>
  ),
  [PositionColumnIds.assetClassLevel4]: ({ position }) => (
    <TextCell>{position?.assetClassLevel4}</TextCell>
  ),
  [PositionColumnIds.ClientAdvisor]: ({ position }) => (
    <TextCell>{position?.ClientAdvisor}</TextCell>
  ),
  [PositionColumnIds.ClientAdvisorTeam]: ({ position }) => (
    <TextCell>{position?.ClientAdvisorTeam}</TextCell>
  )
}
