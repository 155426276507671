import { IDetailsColumnProps, IRenderFunction } from '@fluentui/react'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { DateRanges } from 'shared/dates'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { IAlertColumnDefinition, fieldMapping } from './alerts.types'
import { selectors } from './alertsList'

const HeaderCell: FC<IDetailsColumnProps> = (props) => {
  const { getFilters } = selectors.uiSelectors
  const filters = useSelector(getFilters) ?? {}
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: 4,
        textAlign: 'left',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }}
      title={props?.column.name}
    >
      <span
        title={props?.column.name}
        css={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {props?.column.name}
      </span>

      <div css={{ display: 'flex', columnGap: 8 }}>
        <div>
          {props?.column.isSorted ? (
            <Icon
              type={props?.column.isSortedDescending ? 'Down' : 'Up'}
              height={24}
              title={
                props?.column.isSortedDescending
                  ? 'Sorted Descending'
                  : 'Sorted Ascending'
              }
            />
          ) : (
            <div>
              <Icon type={'ChevronDown'} height={24} width={14} />
            </div>
          )}
        </div>
        {filters[props?.column.key ?? '']?.hasValue && (
          <div>
            {(filters[props?.column.key ?? '']?.facets?.length !==
              filters[props?.column.key ?? '']?.values?.length ||
              !!filters[props?.column.key ?? '']?.value ||
              !!filters[props?.column.key ?? '']?.range ||
              !!filters[props?.column.key ?? '']?.min ||
              !!filters[props?.column.key ?? '']?.max) && (
              <Icon type="FilterApplied" title="Filter Applied" height={24} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const renderHeaderCell: IRenderFunction<IDetailsColumnProps> = (props) => {
  return <HeaderCell {...(props ?? ({} as IDetailsColumnProps))} />
}

export const commonColumnProps: Partial<IAlertColumnDefinition> = {
  filterable: true,
  sortable: true
}
const diplayPin = false
export const alertsColumns: IAlertColumnDefinition[] = [
  ...(diplayPin
    ? [
        {
          name: 'pin',
          displayName: fieldMapping.pin,
          type: 'string' as const,
          width: 60,
          sortable: false,
          filterable: false,
          getValue: ({ id }: { id: string }) => id,
          onRenderHeader: renderHeaderCell
        }
      ]
    : []),
  {
    name: 'tag',
    displayName: fieldMapping.tag,
    type: 'string',
    width: 60,
    sortable: false,
    filterable: false,
    getValue: ({ id }) => id,
    select: [
      'id',
      'alertId',
      'clientid',
      'householdId',
      'alertDetails',
      'owner',
      'receivedWeek',
      'age',
      'currency',
      'isDeleted',
      'resolvedOn'
    ],
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'created',
    displayName: fieldMapping.created,
    dataPath: 'created',
    type: 'date-only',
    minWidth: 100,
    width: 140,
    options: [DateRanges.Today, DateRanges.InLast7Days, DateRanges.Custom],
    maxDate: new Date(),
    getValue: ({ created }) => created,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'source',
    displayName: fieldMapping.source,
    dataPath: 'source',
    type: 'string',
    minWidth: 100,
    width: 180,
    getValue: ({ source }) => source,
    facetable: true,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'alertType',
    displayName: fieldMapping.alertType,
    dataPath: 'alertType',
    type: 'string',
    minWidth: 100,
    width: 160,
    getValue: ({ alertType }) => alertType,
    facetable: true,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'description',
    displayName: fieldMapping.description,
    dataPath: 'description',
    type: 'string',
    minWidth: 120,
    width: 200,
    getValue: ({ description }) => <div title={description}>{description}</div>,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'status',
    displayName: fieldMapping.status,
    dataPath: 'status',
    type: 'string',
    minWidth: 100,
    width: 150,
    getValue: ({ status }) => status,
    facetable: true,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'due',
    displayName: fieldMapping.due,
    dataPath: 'due',
    type: 'date-only',
    minWidth: 120,
    width: 140,
    options: [DateRanges.Today, DateRanges.InLast3Days, DateRanges.Custom],
    getValue: ({ due }) => due,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'resolvedOn',
    displayName: fieldMapping.resolvedOn,
    dataPath: 'resolvedOn',
    type: 'date-only',
    minWidth: 120,
    width: 140,
    options: [DateRanges.Today, DateRanges.InLast3Days, DateRanges.Custom],
    getValue: ({ resolvedOn }) => resolvedOn,
    onRenderHeader: renderHeaderCell,
    hidden: true
  },
  {
    ...commonColumnProps,
    name: 'client',
    displayName: fieldMapping.client,
    dataPath: 'client',
    type: 'string',
    searchable: true,
    minWidth: 100,
    width: 180,
    searchFields: ['client'],
    getValue: ({ client }) => client,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'accountNumber',
    displayName: fieldMapping.accountNumber,
    dataPath: 'accountNumber',
    type: 'string',
    searchable: true,
    minWidth: 80,
    width: 120,
    searchFields: ['accountNumber'],
    getValue: ({ accountNumber }) => accountNumber,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'registrationType',
    displayName: fieldMapping.registrationType,
    dataPath: 'registrationType',
    type: 'string',
    minWidth: 100,
    width: 180,
    getValue: ({ registrationType }) => registrationType,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'holdingsMarketValue',
    displayName: fieldMapping.holdingsMarketValue,
    dataPath: 'holdingsMarketValue',
    type: 'number',
    minWidth: 100,
    width: 160,
    getValue: ({ holdingsMarketValue }) => holdingsMarketValue,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'repCode',
    displayName: fieldMapping.repCode,
    dataPath: 'repCode',
    type: 'string',
    minWidth: 80,
    width: 154,
    getValue: ({ repCode }) => repCode,
    facetable: true,
    onRenderHeader: renderHeaderCell
  },
  {
    ...commonColumnProps,
    name: 'owner',
    displayName: fieldMapping.owner,
    dataPath: 'owner',
    type: 'string',
    minWidth: 100,
    width: 180,
    getValue: ({ owner }) => owner,
    onRenderHeader: renderHeaderCell
  },
  {
    name: 'action',
    displayName: fieldMapping.actions,
    type: 'string',
    minWidth: 100,
    width: 100,
    sortable: false,
    getValue: ({ receivedWeek }) => receivedWeek
  }
]
