import { css } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { maskAccountNumber } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { memo, useMemo } from 'react'
import { isNotNullOrEmpty } from 'shared/guards'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { getIsSBL } from '../../../store/rdot360Context'
import { useRdot360AccountContext } from '../../../store/rdot360Context/useRdot360AccountContext'
import { detailTable } from '../DataTable/DetailTable'
import { AccountTooltip } from './AccountTooltip'
import { HighlightSearchText } from './HighlightSearchText'
import { getRegistration } from './shared'

const marginStyle = css({ fontWeight: 'bold', fontSize: 9, marginLeft: '2px' })
const styles = {
  preWrapWhitespace: css({ whiteSpace: 'pre-wrap' }),
  closedAccount: css({
    textDecoration: 'line-through',
    fontWeight: 'normal',
    fontStyle: 'italic'
  }),
  custodianName: css([
    detailTable.ellipsis,
    { fontWeight: 'normal', fontSize: '11px' }
  ]),
  margin: marginStyle,
  multipleMargin: css([marginStyle, { color: '#8B0000' }])
}

export const AccountTooltipWrapper: React.FC<{
  accountIdOrKey?: string
  search?: string
  fallbackValue?: string
  forceMaskAccountNumber?: boolean
}> = memo(
  ({ accountIdOrKey, search, fallbackValue, forceMaskAccountNumber }) => {
    const {
      accountLookupByAccountIdOrKey,
      cdmv2HouseholdAccountLookupByAccountId
    } = useRdot360AccountContext()
    const account = useMemo(() => {
      return accountIdOrKey
        ? accountLookupByAccountIdOrKey[accountIdOrKey] || {}
        : {}
    }, [accountIdOrKey, accountLookupByAccountIdOrKey])
    const { shouldMask: preferencesShouldMask } = useMasking()
    const shouldMask = !!forceMaskAccountNumber || preferencesShouldMask
    const accountId = account?.accountId ?? ''
    const accountNumber = account?.CustodyAccount ?? ''
    const isExternal = account?.RDOTAccountCategoryCode === '03'
    const accountNumberWithMask = useMemo(
      () =>
        isExternal || shouldMask
          ? maskAccountNumber(accountNumber)
          : accountNumber,
      [isExternal, shouldMask, accountNumber]
    )
    const cdmV2Account = cdmv2HouseholdAccountLookupByAccountId?.[accountId]
    const { addressMappings, establishDate } = cdmV2Account ?? {}
    const canShowTooltip = useMemo(
      () => addressMappings?.[0] ?? establishDate ?? accountNumber,
      [addressMappings, establishDate, accountNumber]
    )

    const plainCardProps = useMemo(
      (): IPlainCardProps => ({
        onRenderPlainCard: () =>
          (!!canShowTooltip && (
            <AccountTooltip
              nicknameAccount={cdmV2Account}
              searchAccount={account}
            />
          )) ||
          null,
        directionalHint: DirectionalHint.topLeftEdge,
        calloutProps: {
          isBeakVisible: true,
          styles: {
            beak: { left: '16px !important' }
          }
        }
      }),
      [account, canShowTooltip, cdmV2Account]
    )

    return (
      <HoverCard
        plainCardProps={plainCardProps}
        type={HoverCardType.plain}
        cardDismissDelay={500}
      >
        <span
          css={(theme) => [
            canShowTooltip && {
              cursor: 'pointer',
              color: theme.colors.tertiaryBlue1
            },
            account?.accountStatus === 'Closed' && styles.closedAccount
          ]}
        >
          <HighlightSearchText
            text={accountNumberWithMask || fallbackValue}
            search={search}
          />
        </span>
        {account?.marginAgreement === 'Yes' && (
          <sup
            css={
              isNotNullOrEmpty(account.multipleMargin)
                ? styles.multipleMargin
                : styles.margin
            }
          >
            M
          </sup>
        )}
      </HoverCard>
    )
  }
)

export const AccountNumberCell: React.FC<{
  accountIdOrKey?: string
  search?: string
  fallbackValue?: string
  wrapNickname?: boolean
  onAccountNumberClick?: () => void
  displayFirmName?: boolean
  forceMaskAccountNumber?: boolean
}> = memo(
  ({
    accountIdOrKey,
    search,
    fallbackValue,
    wrapNickname,
    onAccountNumberClick,
    displayFirmName,
    forceMaskAccountNumber
  }) => {
    const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
    const { mask } = useMasking()
    const account = accountIdOrKey
      ? accountLookupByAccountIdOrKey[accountIdOrKey] ?? {}
      : {}
    const isSBL = getIsSBL(account)
    const nicknameWithMask = mask(account?.preferredNickname, 'nickname')
    const registration = getRegistration(account)
    const isAccountAtDifferentCustodian =
      account?.RDOTAccountCategoryCode === '02' ||
      account?.RDOTAccountCategoryCode === '03'
    const custodianName =
      account?.CustodianName === 'nfs' ? 'NFS' : account?.CustodianName

    return (
      <>
        {nicknameWithMask && (
          <div
            title={nicknameWithMask}
            css={[
              !wrapNickname ? detailTable.ellipsis : styles.preWrapWhitespace
            ]}
          >
            <HighlightSearchText text={nicknameWithMask} search={search} />
          </div>
        )}
        <div
          css={[
            nicknameWithMask
              ? {
                  display: 'flex',
                  gridColumnGap: 4
                }
              : {
                  display: 'grid',
                  flexDirection: 'column'
                }
          ]}
        >
          <span
            onClick={onAccountNumberClick}
            css={[account?.accountStatus === 'Closed' && styles.closedAccount]}
          >
            <AccountTooltipWrapper
              accountIdOrKey={accountIdOrKey}
              search={search}
              fallbackValue={fallbackValue}
              forceMaskAccountNumber={forceMaskAccountNumber || isSBL}
            />
          </span>
          {nicknameWithMask && account?.registrationtype && ' | '}
          <div
            css={[registration.length > 5 && detailTable.ellipsis]}
            title={registration}
          >
            <HighlightSearchText text={registration} search={search} />
          </div>
        </div>
        {displayFirmName && isAccountAtDifferentCustodian && custodianName && (
          <div css={styles.custodianName} title={custodianName}>
            {custodianName}
          </div>
        )}
      </>
    )
  }
)
