import { ISelectableOption } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetRealizedGainLossPossibleYearsQuery } from '../../store/rdot360AnalyticsApi'

export const useYearRangeOptions = (accounts: string[]) => {
  const currentYear = new Date().getFullYear()
  const skip = !accounts?.length
  const { data: possibleYears, isFetching } =
    useGetRealizedGainLossPossibleYearsQuery(skip ? skipToken : { accounts })

  const yearRangeOptions: ISelectableOption[] = useMemo(() => {
    return possibleYears && !isFetching && possibleYears.length > 0
      ? possibleYears
          .map((year) => {
            if (year === currentYear) {
              return {
                key: year,
                text: 'Current Year'
              }
            } else {
              return {
                key: year,
                text: `${year}`
              }
            }
          })
          .sort((a, b) => Number(b.key) - Number(a.key))
      : [{ key: currentYear, text: 'Current Year' }]
  }, [possibleYears, isFetching, currentYear])
  const hasCurrentYear =
    yearRangeOptions.filter((year) => year.text === 'Current Year').length > 0

  return hasCurrentYear
    ? { yearRangeOptions, isFetching, currentYear }
    : {
        yearRangeOptions: [
          {
            key: currentYear,
            text: 'Current Year'
          },
          ...yearRangeOptions
        ],
        isFetching,
        currentYear
      }
}
