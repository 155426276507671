import { ISelectableOption, IDropdownOption } from '@fluentui/react'
import { PerformanceDateRange } from 'api/performance.types'
import { useCallback } from 'react'
import { Dropdown } from '../../components/shared'
import { PerfDateRange, usePerformanceState } from './store'

const filterOptions: ISelectableOption[] = [
  { key: PerformanceDateRange.monthToDate, text: 'MTD' },
  { key: PerformanceDateRange.quarterToDate, text: 'QTD' },
  { key: PerformanceDateRange.yearToDate, text: 'YTD' },
  { key: PerformanceDateRange.inceptionToDate, text: 'ITD' }
]

const PerformanceDateRangeFilter = () => {
  const { dateRange, setDateRange } = usePerformanceState()

  const onDateRangeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      setDateRange(option?.key as PerfDateRange)
    },
    [setDateRange]
  )

  return (
    <div style={{ width: '95px' }}>
      <Dropdown
        options={filterOptions}
        selectedKey={dateRange}
        onChange={onDateRangeChange}
      />
    </div>
  )
}

export default PerformanceDateRangeFilter
