import { css } from '@emotion/react'
import { IconButton, Modal } from '@fluentui/react'
import { useCallback, useMemo, useState } from 'react'

export const getClasses = () => ({
  disclaimerContainer: css({
    fontSize: 10,
    marginTop: 'auto'
  }),
  disclaimerButton: css({
    fontSize: 10,
    padding: '0 3px',
    color: '#2A769D',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  })
})

export const IncomeDisclaimer: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])

  const [showModal, setShowModal] = useState(false)

  const onDismissModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const onLinkClicked = useCallback(() => {
    setShowModal(true)
  }, [])

  return (
    <>
      <div css={classes.disclaimerContainer}>
        <button css={classes.disclaimerButton} onClick={onLinkClicked}>
          Click here for more information on your projected income.
        </button>
        {showModal && (
          <Modal
            isOpen={showModal}
            onDismiss={onDismissModal}
            styles={{ main: { display: 'flex' } }}
          >
            <IconButton
              styles={{
                root: { position: 'absolute', right: 24, top: 24 }
              }}
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={onDismissModal}
            />
            <div
              css={{
                width: 920,
                padding: 72,
                display: 'flex',
                flexDirection: 'column',
                fontSize: 14,
                fontWeight: 500
              }}
            >
              <div css={{ marginBottom: 24 }}>
                Note: Income data includes only Rockefeller accounts at NFS.
                Projected-income calculations are estimates, not guaranteed, and
                are not intended to replace your official account statements.
                These projections are based on current holdings and their most
                recent, regular income distributions. Capital gain distributions
                are not considered income for estimating purposes; these
                calculations only reflect interest and dividend distributions.
                They also may include estimated monthly cash flows from current
                holdings based on an “as of” date and are subject to change.
              </div>
              <div css={{ marginBottom: 24 }}>
                All calculations shown here, including charts, figures and
                graphs, rely upon the quality and accuracy of data supplied from
                other sources. While this information is believed to be true and
                accurate as of the date received, no guarantee is made as to its
                completeness nor its accuracy. There is no guarantee that these
                projected income estimates will be achieved, nor that past
                performance is indicative of future results.
              </div>
              <div>
                Rockefeller Global Family Office does not provide legal or tax
                advice. For tax-reporting purposes, please refer to annual tax
                statements, consisting of various 1099 forms and other
                documents, and consult with a tax or legal professional to
                address your particular situation.
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}
