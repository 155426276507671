import { lazy } from 'react'
import { RdotUserRoleEnum } from '../../../../../../store/user/rdotUser'
import { IAccountsModuleDefinition } from '../../shared/IAccountsModuleDefinition'

const ManagedAccountFeeHistoryContainer = lazy(
  () => import('./ManagedAccountFeeHistoryContainer')
)

const moduleDefinition: IAccountsModuleDefinition = {
  routeProps: {
    path: '/ManagedAccountFeeHistory',
    private: true,
    component: ManagedAccountFeeHistoryContainer
  },
  showRepSelector: true,
  roles: [RdotUserRoleEnum.Advisory_User],
  name: 'Managed Account Fees'
}

export * from './store'
export default moduleDefinition
