import { IDataTableSortBy } from 'features/DataList/common/types'
import { cloneDeep, difference, keyBy, uniq } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { delay, put, select, take, takeLatest } from 'typed-redux-saga'
import { ActionType, createReducer } from 'typesafe-actions'
import { IFacetResult } from '../../../api/common.types'
import {
  constructFilterQuery,
  IOdataCollectionFilter,
  OdataPropertyFilterGroup
} from '../../../api/odata'
import { IOdataRequest } from '../../../api/odata.types'
import { isNotNullOrEmpty, isNotNullOrUndefined } from '../../../shared/guards'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../Lists/core/contracts/IListsFilter'
import { IOdataListUiActions } from '../common/IOdataListUiActions'
import { IOdataListUiSelectors } from '../common/IOdataListUiSelectors'
import { IOdataListUiState } from '../common/IOdataListUiState'
import { convertToOdataFilter } from '../common/service'
import { IOdataListColumnDefinition } from '../common/types'
import { IOdataListDataStore } from './odataListDataStore'
import { createActionWithPrefix } from './service'

export interface ICreateOdataListUiStoreOptions<T, U> {
  prefix: string
  initialState: IOdataListUiState
  rootSelector: (state: U) => IOdataListUiState | undefined
  dataStore: IOdataListDataStore<T, U>
  onConvertToOdataFilter?: (
    filter: IListsFilter
  ) => OdataPropertyFilterGroup | IOdataCollectionFilter | undefined
}

export const createOdataListUiStore = <T, U>(
  options: ICreateOdataListUiStoreOptions<T, U>
) => {
  const {
    prefix,
    dataStore,
    initialState,
    rootSelector,
    onConvertToOdataFilter
  } = options
  const { actions: dataStoreActions, selectors: dataStoreSelectors } = dataStore
  const { filters = {} } = initialState
  const originalFilters = cloneDeep(filters)

  const UPDATE_COLUMNS = '@features/@odataListUi/UPDATE_COLUMNS'
  const UPDATE_SELECTED_COLUMNS =
    '@features/@odataListUi/UPDATE_SELECTED_COLUMNS'
  const RESET_FILTERS = '@features/@odataListUi/RESET_FILTERS'
  const UPDATE_FILTERS = '@features/@odataListUi/UPDATE_FILTERS'
  const UPDATE_FACETS = '@features/@odataListUi/UPDATE_FACETS'
  const UPDATE_SEARCH_TEXT = '@features/@odataListUi/UPDATE_SEARCH_TEXT'
  const UPDATE_SORT = '@features/@odataListUi/UPDATE_SORT'
  const RELOAD = '@features/@odataListUi/RELOAD'

  const actions: IOdataListUiActions = {
    updateColumns: createActionWithPrefix(prefix, UPDATE_COLUMNS)<
      IOdataListColumnDefinition[]
    >(),
    updateSelectedColumns: createActionWithPrefix(
      prefix,
      UPDATE_SELECTED_COLUMNS
    )<string[]>(),
    resetFilters: createActionWithPrefix(prefix, RESET_FILTERS)<
      string[] | undefined
    >(),
    updateFilters: createActionWithPrefix(prefix, UPDATE_FILTERS)<
      Record<string, IListsFilter>
    >(),
    updateFacets: createActionWithPrefix(prefix, UPDATE_FACETS)<
      Record<string, IFacetResult[]>
    >(),
    updateSearchText: createActionWithPrefix(
      prefix,
      UPDATE_SEARCH_TEXT
    )<string>(),
    updateSort: createActionWithPrefix(prefix, UPDATE_SORT)<IDataTableSortBy>(),
    loadMore: dataStoreActions.loadMore,
    reload: createActionWithPrefix(prefix, RELOAD)()
  }

  const reducer = createReducer<
    IOdataListUiState,
    ActionType<IOdataListUiActions>
  >(initialState)
    .handleAction(actions.updateColumns, (state, action) => ({
      ...state,
      columns: action.payload
    }))
    .handleAction(actions.updateSelectedColumns, (state, action) => ({
      ...state,
      selectedColumns: action.payload
    }))
    .handleAction(actions.updateFilters, (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload }
    }))
    .handleAction(actions.updateFacets, (state, action) => {
      const { filters } = state
      const newFacets = action.payload
      const newFilters = { ...filters }

      Object.entries(newFacets).forEach(([key, value]) => {
        if (!newFilters[key]) {
          return
        }
        newFilters[key] = {
          ...newFilters[key],
          facets: value
        } as IListsFacetFilter
        const originalFilter = originalFilters[key] as IListsFacetFilter
        if (!originalFilter) {
          return
        }

        originalFilter.facets = value
      })

      return { ...state, filters: newFilters }
    })
    .handleAction(actions.updateSort, (state, action) => ({
      ...state,
      sortBy: action.payload
    }))
    .handleAction(actions.resetFilters, (state, action) => {
      const partial = action.payload
        ?.map((x) => originalFilters[x])
        .filter(isNotNullOrUndefined)
        .reduce((a, x) => ({ ...a, [x.id]: x }), state.filters)
      return { ...state, filters: partial || originalFilters }
    })
    .handleAction(actions.updateSearchText, (state, action) => ({
      ...state,
      searchText: action.payload
    }))
    .handleAction(actions.reload, (state) => ({
      ...state
    }))

  const getFilters = flow(rootSelector, (x) => x?.filters)
  const getSortBy = flow(rootSelector, (x) => x?.sortBy)
  const getSearchText = flow(rootSelector, (x) => x?.searchText)
  const getColumns = flow(rootSelector, (x) => x?.columns)
  const getSelectedColumns = flow(rootSelector, (x) => x?.selectedColumns)
  const selectors: IOdataListUiSelectors<T, U> = {
    getColumns,
    getSelectedColumns,
    getFilters,
    getIsLoading: dataStoreSelectors.getIsLoading,
    getItems: createSelector([dataStoreSelectors.getChunks], (chunks) =>
      chunks
        ?.filter(isNotNullOrUndefined)
        .flatMap((x) => x.value)
        .filter(isNotNullOrUndefined)
    ),
    getItemsCount: dataStoreSelectors.getTotalCount,
    getSearchText,
    getSortBy: getSortBy,
    getError: dataStoreSelectors.getError,
    getOdataRequest: createSelector(
      [getFilters, getSortBy, getSearchText, getColumns, getSelectedColumns],
      (
        listFilters,
        sortBy,
        searchText,
        columns,
        selectedColumns
      ): IOdataRequest => {
        const odataFilters = Object.values(listFilters || {})
          .filter(({ hasValue }) => hasValue)
          .map((filter) => {
            const result = onConvertToOdataFilter?.(filter)
            return result || convertToOdataFilter(filter)
          })
          .filter(isNotNullOrUndefined)
        const filter = constructFilterQuery(odataFilters)

        const columnLookup = keyBy(columns, (x) => x.name)

        const selectColumns =
          selectedColumns?.map((x) => columnLookup[x]) || columns

        return {
          filters: [filter].filter(isNotNullOrEmpty),
          orderby: sortBy && [
            {
              dataPath: columnLookup[sortBy.name]?.dataPath || '',
              direction: sortBy.direction
            }
          ],
          expand: uniq(
            columns?.flatMap((x) => x.expand).filter(isNotNullOrEmpty)
          ),
          select: uniq(
            [
              ...(selectColumns || []).map(
                ({ dataPath, collectionPath }) => collectionPath || dataPath
              ),
              ...(selectColumns || []).flatMap((x) => x.select)
            ].filter(isNotNullOrEmpty)
          ),
          search: searchText,
          searchFields: uniq(
            (columns || [])
              .flatMap((x) => x.searchFields)
              .filter(isNotNullOrEmpty)
          )
        }
      }
    )
  }

  const onUiStateChange = function* () {
    yield* delay(300)
    const request = yield* select(selectors.getOdataRequest)
    yield put(dataStoreActions.request(request))
  }

  const sagas = [
    () =>
      takeLatest(
        [
          actions.updateFilters,
          actions.updateSort,
          actions.updateSearchText,
          actions.resetFilters,
          actions.reload
        ],
        onUiStateChange
      ),
    function* () {
      while (true) {
        const selectedColumns = yield* select(selectors.getSelectedColumns)
        yield take([actions.updateSelectedColumns])

        if (!selectedColumns?.length) {
          return
        }

        const newSelectedColumns = yield* select(selectors.getSelectedColumns)

        const diff = difference(newSelectedColumns, selectedColumns)

        if (diff.length) {
          console.debug('odata list column update request', diff)
          yield put(actions.reload(undefined))
        }
      }
    }
  ]

  return { actions, reducer, selectors, sagas }
}
