import { flexRender, Table } from '@tanstack/react-table'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import { investmentsTableColumnNames } from './shared'
import { useInvestmentsTableStore } from './store'
import { cellStyles } from './styles'

export enum SortingType {
  asc = 'asc',
  desc = 'desc',
  default = ''
}
export const InvestmentsTableHeaderRow: React.FC<{
  table: Table<ICategoryPosition>
}> = ({ table }) => {
  const headers = table.getFlatHeaders()
  const { setSorting, grouping } = useInvestmentsTableStore()

  return (
    <tr css={[rdot360TableStyles.headerRow]}>
      {headers.map((header) => {
        return (
          <th
            key={header.id}
            css={[cellStyles[header.id]]}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (
                header.column.id === investmentsTableColumnNames.description
              ) {
                const sortState = header.column.getIsSorted()
                const updatedIDList =
                  sortState === SortingType.asc
                    ? [...(grouping || [])]
                    : [...(grouping || []), header.column.id]
                const desc =
                  sortState === false || sortState === SortingType.asc
                setSorting(
                  updatedIDList?.map((id) => {
                    return {
                      id,
                      desc
                    }
                  }) || []
                )
                return
              }

              header.column.toggleSorting()
            }}
          >
            <div style={{ display: 'inline-flex' }}>
              <span>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </span>
              <SortIndicator direction={header.column.getIsSorted()} />
            </div>
          </th>
        )
      })}
    </tr>
  )
}
