import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { IColumnState } from 'features/Lists/core/contracts/IColumnState'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { partial } from 'lodash/fp'
import { AlertColumnIdsEnum } from 'modules/Advisory/modules/Alerts/store/alerts.types'
import { AlertColumnDefinitions } from './ColumnDefinitions'

const defaultColumns = [
  'pin',
  'tag',
  'created',
  'source',
  'alertType',
  'description',
  'status',
  'due',
  'client',
  'accountNumber',
  'registrationType',
  'holdingsMarketValue',
  'repCode',
  'owner'
]

export const createDefaultColumnState = (
  columnDefinitions: Record<string, IColumnDefinition>,
  defaultColumns: string[],
  numStickyColumns = 0
) => {
  const columnPositionMap: { [key: string]: number } = defaultColumns.reduce(
    (a, x, i) => ({ ...a, [x]: i }),
    {}
  )

  const entries = Object.entries(columnDefinitions)

  const columnState: IColumnState[] = entries
    .map(([id, value]) => ({
      columnId: id,
      includeInSearch: value.searchable || false,
      selected: columnPositionMap[id] != null,
      sticky: false,
      width: value.width || 150
    }))
    .sort(
      (a, b) =>
        (columnPositionMap[a.columnId] ?? entries.length) -
        (columnPositionMap[b.columnId] ?? entries.length)
    )

  for (let i = 0; i < numStickyColumns; i++) {
    columnState[i].sticky = true
  }

  return columnState
}

export const constructInitialListColumnState = partial(
  createDefaultColumnState,
  [AlertColumnDefinitions]
)

export const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialListColumnState(defaultColumns),
  filters: {},
  orderBy: { columnId: AlertColumnIdsEnum.created, direction: 'desc' }
}
