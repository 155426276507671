import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
  Separator,
  Stack,
  Text
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query'
import React, { useMemo } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { useUserSettings } from 'shared/hooks/useUserSettings'
import { USDCell } from '../core/components/USDCell'
import { useGetAfterValuationHistoryQuery } from './store'

export const AdjustedMarketValueCell: React.FC<{
  adjustedMarketValue?: number
  id?: string
}> = ({ adjustedMarketValue, id }) => {
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => <HoverContent id={id} />
    }),
    [id]
  )

  const { enablePreviewFeatures } = useUserSettings()

  if (!enablePreviewFeatures) {
    return <USDCell value={adjustedMarketValue} fractionDigits={0} />
  }

  return (
    <HoverCard plainCardProps={plainCardProps} type={HoverCardType.plain}>
      <Link style={{ textDecoration: 'none' }}>
        <USDCell value={adjustedMarketValue} fractionDigits={0} />
      </Link>
    </HoverCard>
  )
}

const HoverContent: React.FC<{ id?: string }> = ({ id }) => {
  const { data, isFetching } = useGetAfterValuationHistoryQuery(id || skipToken)
  return (
    <Stack tokens={{ childrenGap: 5, padding: 20 }}>
      {!isFetching && (
        <Stack.Item>
          <div style={{ textAlign: 'center' }}>
            <Text styles={{ root: { fontWeight: 'bold' } }}>
              After Valuation History
            </Text>
          </div>
          <Separator styles={{ root: { padding: 0 } }} />
        </Stack.Item>
      )}
      {isFetching && <LoadingComponent />}
      {!isFetching && !!data?.length && (
        <table
          style={{
            tableLayout: 'fixed',
            width: '450px',
            textAlign: 'right'
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Transaction Description</th>
              <th>Transaction Amount</th>
              <th>Transaction Type</th>
              <th>Trade Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((transaction, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'left' }}>
                  <Text>{transaction.TransactionDescription}</Text>
                </td>
                <td>
                  <USDCell value={transaction.TransactionAmount} />
                </td>
                <td>
                  <Text>{transaction.TransactionType}</Text>
                </td>
                <td>
                  <Text>{transaction.TradeDate}</Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isFetching && !data?.length && <Text>No data found</Text>}
    </Stack>
  )
}
