import { IFacetResult } from 'api/common.types'
import { IOrderBy } from 'api/odata.types'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../features/OdataList/common/types'

export interface IAlert {
  '@search.score': number
  id: string
  alertType: string
  registrationType: string
  source: string
  status: string
  description: string
  created: string
  due: string
  client: string
  clientid: string
  householdId: string
  householdName: string
  repCode: string
  accountNumber: string
  accountNumberId: string
  accountType: string
  receivedWeek: string
  holdingsMarketValue: number
  rid: string
  owner?: string
  isArchived?: boolean
  isDeleted?: boolean
  age?: string
  currency?: string
  resolvedOn?: string
  alertDetails: IAlertDetail[]
  [key: string]: any
}

export interface IAlertDetail {
  key: string
  value: string
}

export interface IOdataFacetResult extends IOdataResult<IAlert> {
  '@search.facets': Record<string, IFacetResult[]>
}

export type AlertColumnName =
  | 'pin'
  | 'tag'
  | 'created'
  | 'source'
  | 'alertType'
  | 'description'
  | 'status'
  | 'client'
  | 'accountNumber'
  | 'registrationType'
  | 'holdingsMarketValue'
  | 'repCode'
  | 'owner'
  | 'action'
  | 'due'
  | 'resolvedOn'

export enum AlertColumnIdsEnum {
  pin = 'pin',
  tag = 'tag',
  created = 'created',
  source = 'source',
  alertType = 'alertType',
  description = 'description',
  status = 'status',
  client = 'client',
  accountNumber = 'accountNumber',
  registrationType = 'registrationType',
  holdingsMarketValue = 'holdingsMarketValue',
  repCode = 'repCode',
  owner = 'owner',
  action = 'action',
  due = 'due',
  resolvedOn = 'resolvedOn'
}

export const fieldMapping: Record<string, string> = {
  pin: 'PIN',
  tag: 'Tag',
  created: 'Created',
  source: 'Source',
  alertType: 'Alert Type',
  description: 'Description',
  status: 'Status',
  client: 'Account Name',
  accountNumber: 'Account #',
  registrationType: 'Registration Type',
  holdingsMarketValue: 'Alert Amount',
  repCode: 'Rep Code',
  actions: 'Action',
  due: 'Due',
  owner: 'Owner',
  isArchived: 'Is Archived',
  resolvedOn: 'Resolved On'
} as const

export interface IAlertColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IAlert> {
  name: string
  minDate?: Date
  maxDate?: Date
  searchable?: boolean
}

export interface IOdataRequest {
  queryType?: string
  orderby?: IOrderBy[] | string
  filters?: string[]
  filter?: string
  select?: string[] | string
  expand?: string[] | string
  top?: number
  skip?: number
  search?: string
  searchFields?: string[]
  count?: boolean
  apply?: string
  facets?: string[]
}
export interface AlertsBatchResponse {
  responses: Response[]
}

export interface Response {
  id: string
  status: number
  headers: Headers
  body: Body
}

export interface Headers {
  server: string
  'strict-transport-security': string
  'preference-applied': string
  'odata-version': string
  'request-id': string
  'elapsed-time': string
  date: string
  'content-type': string
  'content-length': string
}

export interface Body {
  '@odata.context': string
  value: Value[]
}

export interface Value {
  '@search.score': number
  id: string
  alertId: string
  alertType: string
  registrationType: string
  source: string
  status: string
  description?: string
  created: string
  due: string
  resolvedOn: string
  client: string
  clientid: string
  householdId?: string
  repCode: string
  accountNumber: string
  receivedWeek: string
  owner: any
  holdingsMarketValue: number
  isArchived: any
  isDeleted: boolean
  currency: any
  age: any
  alertDetails: AlertDetail[]
}

export interface AlertDetail {
  key: string
  value?: string
}
